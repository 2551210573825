import { uiActions } from '..';
import { authGet, authPost, authDelete } from '../../lib';

export const resourceActions = {
  /**
   *
   * @param {object} options
   * @param {string} options.baseUrl
   * @param {(string|int)} options.id
   * @param {string} [options.path] the path after the id
   * @param {string|int} [options.query]
   * @param {string} [options.showErrorMessage] If true the error message will be displayed. Default true.
   * @param {object} [options.config]
   * @returns {normalizeReturn}
   */
  fetchResourceById({
    baseUrl,
    id,
    path: _path,
    query = {},
    showErrorMessage = true,
    config = null,
  }) {
    return async (dispatch) => {
      const path = _path ? `/${_path}` : '';
      const response = await authGet(
        [`${baseUrl}/${id}${path}`, query],
        undefined,
        undefined,
        config,
      );
      dispatch(uiActions.setListLoading(false));
      const { data, error } = response;
      if (error) {
        if (showErrorMessage) {
          dispatch(
            uiActions.showError({
              message: error.message || 'Something went wrong',
            }),
          );
        }
        return { error };
      }
      return { data };
    };
  },

  /**
   *
   * @param {fetchResourceById} fetchListProps
   * @returns {normalizeReturn}
   */
  deleteResource({ baseUrl, id, query = {} }) {
    return async (dispatch) => {
      const response = await authDelete([`${baseUrl}/${id}`, query]);
      dispatch(uiActions.setListLoading(false));
      const { error, data } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }

      return { data };
    };
  },

  /**
   *
   * @param {object} options
   * @param {string} options.baseUrl
   * @param {object} [options.query]
   * @param {object} options.payload
   * @param {string} [options.message]
   * @returns {normalizeReturn}
   */
  postResource({ baseUrl, query = {}, payload = {}, message }) {
    return async (dispatch) => {
      const response = await authPost([baseUrl, query], {
        ...payload,
      });
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      if (message) {
        dispatch(uiActions.showNotification({ message }));
      }
      return { data };
    };
  },
};

// #region Typedefs

/**
 * @typedef {Object} normalizeReturn
 * @property {objeck} [data]
 * @property {number} [error]
 */
// #endregion
