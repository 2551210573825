import makeStyles from '@mui/styles/makeStyles';
export const useStyles = makeStyles(
  (theme) => ({
    content: {
      paddingLeft: 64,
      paddingRight: 64,

      '@media print': {
        padding: 0,
      },
    },
    contentPaddingLeft: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 272,
      },
    },
    pageContent: {
      padding: 24,

      '@media print': {
        padding: 0,
      },
    },
    logoutWarning: {
      padding: 64,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 400,
    },
    logoutWarningHeader: {
      fontWeight: 600,
      fontSize: 20,
      marginBottom: 20,
    },
    logoutWarningText: {
      marginBottom: 32,
      fontSize: 16,
      fontWeight: 500,
    },
  }),
  {
    classNamePrefix: 'Layout',
  },
);
