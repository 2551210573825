import React, { useState, useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { getYear } from 'date-fns';
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Dialog,
  Slide,
  useTheme,
} from '@mui/material';
import { object, string, array } from 'yup';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useGetUsersData, useNotify } from '../../hooks';
import { SelectInput } from '../../components';
import { authGet, residentStatusTypes, Routes } from '../../lib';
import {
  facilityActions,
  facilitySelectors,
  uiSelectors,
  caseSelectors,
  caseActions,
  uiActions,
} from '../../state';

import { useStyles } from './dashboardPage.styles';
import {
  GetListFieldsDefaults,
  ListCategoryTypes,
} from './ListViews/helpers/ListHelpers';
import { ResidentListTypes } from './ListViews/helpers/ResidentListConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function OldDashboardPage() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { companyType, firstName } = useGetUsersData();

  const facilities = useSelector(facilitySelectors.facilityOptions);
  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);
  const caseTagsList = useSelector(caseSelectors.companyCaseTagOptions);

  const [facility, setFacility] = useState([]);
  const [data, setData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const hasNRIWorkflow = facilities?.some((f) => f.hasNRIWorkflow);
  const defaultFields = GetListFieldsDefaults(
    ListCategoryTypes.Resident,
    ResidentListTypes.All,
    companyType,
    hasNRIWorkflow,
  );
  const nriStatusFieldAddOn = {
    fields: [
      ...defaultFields,
      {
        field: 'NRIStatus',
        sortOrder: defaultFields.length + 1,
      },
    ].map((f) => f.field + '|sort' + f.sortOrder),
  };

  const getTagIds = useCallback(
    (systemTagType) =>
      caseTagsList.filter((t) => t.type === systemTagType).map((t) => t.id),
    [caseTagsList],
  );

  const urTags = useMemo(() => getTagIds('UR'), [getTagIds]);
  const highRiskTags = useMemo(() => getTagIds('HighRisk'), [getTagIds]);
  const spenddownTags = useMemo(() => getTagIds('Spenddown'), [getTagIds]);
  const incomeTrustTags = useMemo(() => getTagIds('IncomeTrust'), [getTagIds]);
  const guardianTags = useMemo(() => getTagIds('Guardian'), [getTagIds]);
  const outsourcedTags = useMemo(() => getTagIds('Outsourced'), [getTagIds]);
  const ssiTags = useMemo(() => getTagIds('SSI'), [getTagIds]);
  const legalTags = useMemo(() => getTagIds('Legal'), [getTagIds]);
  const omhTags = useMemo(() => getTagIds('OMH'), [getTagIds]);

  useEffect(() => {
    dispatch(facilityActions.getFacilities());
    dispatch(caseActions.getCompanyCaseTagsOptions());
  }, [dispatch]);

  useEffect(() => {
    (async function () {
      const response = await authGet([
        '/cases/dashboard',
        { facilityIds: facility },
      ]);
      const { error, data } = response;
      if (error) {
        return notify('Something went wrong', 'error');
      }
      setData(data);
    })();
  }, [facility, notify]);

  const onChange = useCallback((e) => {
    const { value } = e.target;
    if (value.indexOf('Clear') > -1) {
      return setFacility([]);
    }
    setFacility(value);
  }, []);

  const handleSwitchToNewDashboard = useCallback(
    () => dispatch(uiActions.setIsNewDashboardOn(true)),
    [dispatch],
  );

  return (
    <Box sx={{ maxWidth: 1200 }}>
      <img
        src='/images/dashboard-logo.png'
        alt=''
        className={clsx(
          { [classes.logoSidebarOpen]: isSidebarOpen },
          { [classes.logoSidebarClosed]: !isSidebarOpen },
        )}
        draggable={false}
      />
      <div className={classes.content}>
        <div className={classes.titleContainer}>
          <span className={classes.welcomeBack}>Welcome back, </span>
          <span>{firstName}</span>
          <Button
            variant='outlined'
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              ml: '24px',
            }}
            onClick={handleSwitchToNewDashboard}
          >
            Try out our new dashboard
          </Button>
        </div>
        <div className={classes.caseload}>
          <div className={classes.caseloadToolbar}>
            <span className={classes.boxTitle}>Caseload</span>
            <SelectInput
              fullWidth
              className={classes.inputRow}
              value={facility}
              name='facility'
              onChange={onChange}
              menuItems={[
                {
                  id: 'Clear',
                  name: 'Clear',
                  isResetOption: true, // to remove the checkbox
                },
                ...facilities,
              ]}
              label='Facility'
              schema={schema}
              SelectProps={{
                multiple: true,
              }}
            />
          </div>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <div className={classes.smallBoxContainer}>
              <SmallBox
                classes={classes}
                number={data.profileStartedCount}
                filter='New Admissions'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'case-statuses': residentStatusTypes.NewAdmission,
                  facilities: facility,
                })}`}
              />
              <SmallBox
                classes={classes}
                number={data.deniedCount}
                filter='Denied'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'application-statuses': 'Denied',
                  facilities: facility,
                })}`}
                numberColor='#F91429'
              />
              <SmallBox
                classes={classes}
                number={data.ltcPayerNeededCount}
                filter='Long Term'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'case-statuses': residentStatusTypes.LongTerm,
                  facilities: facility,
                })}`}
              />
              <SmallBox
                classes={classes}
                number={data.ineligibleForMedicaidCount}
                filter='Ineligible'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'application-statuses': 'IneligibleForMedicaid',
                  facilities: facility,
                })}`}
                numberColor={theme.palette.primary.light}
              />
            </div>
            <div className={classes.largeBoxContainer}>
              <div>
                {/* <TallBox
                  classes={classes}
                  // number={data.applyingTotal}
                  filter='In progress'
                  link={`${Routes.residentsList.path}?${stringify({
                    'list-type': residentListInProgress,
                    facilities: facility,
                  })}`}
                  data={
                    [
                      // { name: 'Preparing', value: data.preparationCount },
                      // { name: 'Submitted', value: data.submittedCount },
                      // { name: 'CW request', value: data.caseworkerRequestCount },
                    ]
                  }
                /> */}
                <TallBox
                  classes={classes}
                  number={data.approvedTotal}
                  filter='Approved'
                  link={`${Routes.residentsList.path}?${stringify({
                    'list-type': ResidentListTypes.All,
                    'application-statuses': ['Approved', 'AdjustmentNeeded'],
                    facilities: facility,
                  })}`}
                  data={[
                    { name: 'Approved', value: data.approvedCount },
                    {
                      name: 'Adjustment',
                      value: data.AdjustmentNeededCount,
                    },
                  ]}
                />
              </div>
              {data.assignedApplications &&
                data.assignedApplications.filter((a) => a.applicationCount > 0)
                  .length > 0 && (
                  <TallBox
                    classes={classes}
                    filter='Applications'
                    showSeeMore={true}
                    doubleWidth={true}
                    onClick={() => setDialogOpen(true)}
                    data={
                      data.assignedApplications
                        ? data.assignedApplications
                            .filter((a) => a.applicationCount !== 0)
                            .slice(0, 6)
                            .map((a) => {
                              return {
                                name: a.name,
                                value: a.applicationCount,
                              };
                            })
                        : []
                    }
                  />
                )}
              {dialogOpen && data.assignedApplications && (
                <Dialog
                  TransitionComponent={Transition}
                  onClose={() => setDialogOpen(false)}
                  open={dialogOpen}
                  fullWidth={true}
                  maxWidth={'sm'}
                >
                  <div
                    className={classes.boxTitle}
                    style={{ marginTop: 32, marginLeft: 20 }}
                  >
                    {'Applications'}
                  </div>

                  {/* <List sx={{ pt: 0 }}> */}
                  <div
                    style={{
                      marginLeft: 15,
                      marginTop: 10,
                      marginBottom: 20,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {data.assignedApplications
                          .slice(
                            0,
                            Math.ceil(data.assignedApplications.length / 2),
                          )
                          .map((a, i) => (
                            // <ListItem key={i}>
                            <FilterItem
                              key={i}
                              classes={classes}
                              number={a.applicationCount}
                              filter={a.name}
                              background='rgba(15, 1, 144, 0.6)'
                            />
                            //  {/* </ListItem> */}
                          ))}
                      </Grid>
                      <Grid item xs={6}>
                        {data.assignedApplications
                          .slice(
                            Math.ceil(data.assignedApplications.length / 2),
                            data.assignedApplications.length,
                          )
                          .map((a, i) => (
                            // <ListItem key={i}>
                            <FilterItem
                              key={i}
                              classes={classes}
                              number={a.applicationCount}
                              filter={a.name}
                              background='rgba(15, 1, 144, 0.6)'
                            />
                            //  {/* </ListItem> */}
                          ))}
                      </Grid>
                    </Grid>
                  </div>
                  {/* </List> */}
                  {/* <div sx={{ pt: 0 }}>
                      {data.assignedApplications.map((a) => (
                        <div>
                          {a.name} {a.applicationCount}
                        </div>
                      ))}
                    </div> */}
                </Dialog>
              )}
            </div>
          </Box>
        </div>

        <Grid container spacing={4} sx={{ mt: 9, mb: 9 }}>
          <FilterItem
            classes={classes}
            number={data.urCount}
            filter='UR'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': urTags,
              facilities: facility,
            })}`}
            background='rgba(15, 1, 144, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.spendDownCount}
            filter='Spending down'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': spenddownTags,
              facilities: facility,
            })}`}
            background='rgba(9, 110, 248, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.incomeTrustCount}
            filter='IncomeTrust'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': incomeTrustTags,
              facilities: facility,
            })}`}
            background='rgba(238, 6, 143, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.highRiskCount}
            filter='High risk'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': highRiskTags,
              facilities: facility,
            })}`}
            background='rgba(249, 20, 41, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.guardianCount}
            filter='Guardian'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': guardianTags,
              facilities: facility,
            })}`}
            background='rgba(191, 143, 38, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.outsourcedCount}
            filter='Outsourced'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': outsourcedTags,
              facilities: facility,
            })}`}
            background='rgba(6, 27, 238, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.ssiCount}
            filter='SSI'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': ssiTags,
              facilities: facility,
            })}`}
            background='rgba(67, 143, 44, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.legalCount}
            filter='Legal'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': legalTags,
              facilities: facility,
            })}`}
            background='rgba(170, 77, 122, 0.6)'
          />
          <FilterItem
            classes={classes}
            number={data.omhCount}
            filter='OMH'
            link={`${Routes.residentsList.path}?${stringify({
              'list-type': ResidentListTypes.All,
              'case-tags': omhTags,
              facilities: facility,
            })}`}
            background='rgba(243, 137, 62, 0.6)'
          />
          {hasNRIWorkflow && (
            <>
              <FilterItem
                classes={classes}
                number={data.newNRICount}
                filter='NRI Needed'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'nri-statuses': 'New',
                  facilities: facility,
                  ...nriStatusFieldAddOn,
                })}`}
                background='rgba(206, 18, 71, 0.6)'
              />
              <FilterItem
                classes={classes}
                number={data.inReviewNRICount}
                filter='NRI In Review'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'nri-statuses': 'InReview',
                  facilities: facility,
                  ...nriStatusFieldAddOn,
                })}`}
                background='rgba(206, 18, 71, 0.6)'
              />
              <FilterItem
                classes={classes}
                number={data.rejectedNRICount}
                filter='NRI Rejected'
                link={`${Routes.residentsList.path}?${stringify({
                  'list-type': ResidentListTypes.All,
                  'nri-statuses': 'Rejected',
                  facilities: facility,
                  ...nriStatusFieldAddOn,
                })}`}
                background='rgba(206, 18, 71, 0.6)'
              />
            </>
          )}
        </Grid>
        <div className={classes.footerContainer}>
          <div className={classes.footerToolbar}>
            <span className={classes.footerText}>
              <span style={{ marginRight: 5 }}>&#169;</span>{' '}
              <span style={{ marginRight: 5 }}>{getYear(new Date())}</span>
              <span>Aidace. All rights reserved.</span>
            </span>
            <div className={classes.flex}>
              <span
                className={classes.footerText}
                style={{ fontWeight: 500, marginRight: 5 }}
              >
                Support:
              </span>
              <span className={classes.footerText}>support@aidace.com</span>
            </div>
            <MuiLink
              underline='none'
              href='https://www.aidace.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </MuiLink>
          </div>
        </div>
      </div>
    </Box>
  );
}

function SmallBox({ classes, number, filter, link, numberColor, boxStyle }) {
  return (
    <Button
      className={classes.smallBox}
      style={boxStyle}
      classes={{ label: classes.boxLabel }}
      component={Link}
      to={link}
    >
      <span className={classes.boxNumber} style={{ color: numberColor }}>
        {number}
      </span>
      <span className={classes.boxText}>{filter}</span>
    </Button>
  );
}

function TallBox({
  classes,
  number,
  filter,
  link,
  data,
  doubleWidth,
  showSeeMore = false,
  onClick,
}) {
  return (
    <Button
      className={classes.tallBox}
      classes={{ label: classes.boxLabel }}
      style={{
        background:
          filter === 'Applying' || filter === 'In progress'
            ? '#F5F6FE'
            : filter === 'Applications'
            ? '#ECEBF5'
            : '#0F0190',
      }}
      component={link ? Link : 'div'}
      to={link}
      onClick={onClick}
    >
      <span
        className={classes.boxNumber}
        style={{
          color:
            filter === 'Applying' ||
            filter === 'In progress' ||
            filter === 'Applications'
              ? '#0FC076'
              : '#FFFFFF',
        }}
      >
        {number}
      </span>
      <span
        className={classes.boxText}
        style={{
          color:
            filter === 'Applying' ||
            filter === 'In progress' ||
            filter === 'Applications'
              ? '#000000'
              : '#E5E7F5',
          fontWeight: filter === 'Approved' ? 500 : undefined,
        }}
      >
        {filter}
      </span>
      <hr
        className={classes.tallBoxDivider}
        style={{
          border:
            filter === 'Approved' ? '1px solid #D5D8EC' : '1px solid #D5D8EC',
        }}
      />
      {data.map((d, i) => {
        const { displayName, name, value } = d;
        return (
          <div
            key={i}
            style={{
              marginBottom: i < data.length - 1 ? 5 : undefined,
              display: 'grid',
              gridTemplateColumns: '40px 1fr',
            }}
          >
            <span
              style={{
                color:
                  filter === 'Applying' ||
                  filter === 'In progress' ||
                  filter === 'Applications'
                    ? '#019592'
                    : '#FFFFFF',
                marginRight: 13,
                minWidth: 10,
                display: 'inline-block',
              }}
            >
              {value}
            </span>
            <span
              style={{
                color:
                  filter === 'Applying' ||
                  filter === 'In progress' ||
                  filter === 'Applications'
                    ? '#8A91A3'
                    : '#E5E7F5',
              }}
            >
              {displayName || name}
            </span>
          </div>
        );
      })}
      {showSeeMore && <span style={{ marginTop: 10 }}>See More</span>}
    </Button>
  );
}

function FilterItem({ classes, background, number, filter, link, largeCell }) {
  return (
    <Grid item xs={largeCell ? 5 : 4}>
      <Button
        className={classes.bottomFilterBtn}
        style={{
          whiteSpace: 'nowrap',
          cursor: link ? 'pointer' : 'default',
          backgroundColor: 'transparent',
        }}
        component={link ? Link : 'div'}
        to={link}
      >
        <span className={classes.bottomFilterBox} style={{ background }}>
          {number}
        </span>
        <span className={classes.boxText}>{filter}</span>
      </Button>
    </Grid>
  );
}

const schema = object().shape({
  facility: array(string()).typeError('This field must be an array').nullable(),
});
