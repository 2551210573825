import { memo } from 'react';
import {
  Chip as MuiChip,
  ChipProps,
  SvgIconProps,
  TypographyProps,
  Typography,
  alpha,
} from '@mui/material';
import { FlexCenter } from '../ui';
import { IconType } from '../../lib';

type Props = {
  variant?: ChipProps['variant'];
  label: string;
  labelVariant?: TypographyProps['variant'];
  color?: string;
  Icon?: IconType;
  iconProps?: SvgIconProps;
};

export const Chip = memo(function ({
  variant = 'filled',
  label,
  labelVariant,
  iconProps = {},
  color,
  Icon,
}: Props) {
  return (
    <MuiChip
      variant={variant}
      label={
        <FlexCenter>
          {Icon && (
            <Icon
              fontSize='tiny'
              color='disabled'
              sx={{ marginRight: 0.5 }}
              {...iconProps}
            />
          )}
          <Typography
            noWrap
            variant={labelVariant}
            {...(color ? { color } : {})}
          >
            {label}
          </Typography>
        </FlexCenter>
      }
      size='small'
      sx={{
        ...(variant === 'filled' &&
          color && { backgroundColor: alpha(color, 0.08) }), // background color should always be the same color as the text, with 8% opacity
        borderRadius: '6px',
        width: 'fit-content',
        maxWidth: '125px',
        '& .MuiChip-label': {
          paddingX: '4px',
        },
      }}
    />
  );
});
