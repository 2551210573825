import { createSlice } from '@reduxjs/toolkit';
const residentProps = {
  stayDetails: {},
  rfmsInfo: {},
  coverageGapBreakdown: [],
  coverageGapOverrides: [],
  //...resident overview data
};

const initialState = {
  resident: {
    ...residentProps,
  },
  spouse: {
    ...residentProps,
  },
};

export const residents = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    setResidentStayDetails(state, action) {
      state.resident.stayDetails = action.payload;
    },
    setResidentSpouseStayDetails(state, action) {
      state.spouse.stayDetails = action.payload;
    },
    setResidentOverview(state, action) {
      state.resident = { ...state.resident, ...action.payload };
    },
    setSpouseOverview(state, action) {
      state.spouse = { ...state.spouse, ...action.payload };
    },
    setResidentLongTermPayer(state, action) {
      state.resident.longTermCoverage = action.payload;
    },
    clearResidentAndSpouse(state, action) {
      state.resident = { ...residentProps };
      state.spouse = { ...residentProps };
    },
    setRFMSInfo(state, action) {
      state.resident.rfmsInfo = action.payload;
    },
    setResidentCoverageGapBreakdown(state, action) {
      state.resident.coverageGapBreakdown = action.payload;
    },
    setSpouseCoverageGapBreakdown(state, action) {
      state.spouse.coverageGapBreakdown = action.payload;
    },
    setResidentCoverageGapOverrides(state, action) {
      state.resident.coverageGapOverrides = action.payload;
    },
    setSpouseCoverageGapOverrides(state, action) {
      state.spouse.coverageGapOverrides = action.payload;
    },
  },
});
