import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  Fragment,
  isValidElement,
  cloneElement,
  useRef,
} from 'react';
import {
  DialogActions,
  Grid,
  Select,
  MenuItem,
  Button,
  Dialog,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '../ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash.isequal';
import { FormModal, DeleteContent } from './FormModal';
import {
  SelectInput,
  DateInput,
  TextInput,
  RadioInput,
  CheckboxInput,
  AutocompleteInput,
} from '../common/inputs';
import { ReadOnlyComponent } from '../common';
import { useGetUsersData, useNotify } from '../../hooks';
import {
  resourceActions,
  uiActions,
  applicationActions,
  systemPersistSelectors,
  uiSelectors,
} from '../../state';
import {
  applicationStatusTypes,
  convertNullFieldsToEmptyString,
  uploadDocuments,
  uploadDocumentCategories,
  validateDocsRequired,
  validateOnSubmit,
  companySettingTypes,
  checkHasSetting,
  formatDate,
  companyTypes,
} from '../../lib';
import Prompt from '../../lib/routing/Prompt';
import {
  statusChangeFieldsComponent,
  initialStatusChangeFields,
} from './ApplicationStatusComponents';
import {
  applicationSchema as schema,
  applicationStatusChangeSchema as statusChangeSchema,
} from './schema';
import { sharedFormStyles } from './sharedStyles';
import { AssigneeAutocompleteInput } from '../../pages/main/caseDetails/application/AssigneeAutocompleteInput';
import { getColorForApplicationStatus } from '../../pages/main/caseDetails/application/applicationHelpers';
import { clsx } from 'clsx';
import { MedicaidCaseworkerDisplayInput } from '../../pages/main/caseDetails/application/MedicaidCaseworkerDisplayInput';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { CheckCircleIcon } from '../../themes';

const useStyles = makeStyles((theme) => ({
  ...sharedFormStyles(theme),
  customStatusDropdown: {
    borderRadius: 4,
    textTransform: 'uppercase',
    marginBottom: 18,
    '& .MuiSelect-select': {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#FFFF',
      '&.Mui-disabled': {
        textFillColor: '#FFFF',
      },
    },

    '& .MuiSelect-icon': {
      color: '#FFFF',
    },
  },
  helperTextInput: {
    marginBottom: 13,
  },
  titleRow: {
    marginRight: -8,
  },
  title: {
    fontSize: 22,
    fontWeight: 600,
  },
  smallLabel: {
    '& span': { fontSize: 14 },
  },
  smallRow: {
    padding: 17,
  },
  greatJobModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 46,
  },
  greatJobIcon: {
    width: 92,
    height: 92,
    color: '#0FC076',
  },
  okButton: {
    margin: 24,
    width: 90,
  },
  greatJobTitle: {
    fontSize: 28,
    fontWeight: 600,
    marginTop: 14,
  },
  greatJobText: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    marginTop: 8,
  },
}));

export const initialApplicationFields = {
  assigneeIDs: [],
  anticipatedMedicaidDate: null,
  applicationType: '',
  applicationStatus: '',
  info: '',
  medicaidCaseworkerID: '',
  medicaidNeededBy: null,
  startedOn: null,
  renewalDate: null,
  personID: null,
  countyID: null,
  isOutsourced: false,
  outsourceCompanyContactID: '',
  medicaidCaseID: '',
};

export function ApplicationForm({
  applicationId,
  applicationDetails, //these will be just the case details part of it for a new application
  caseId,
  facilityState,
  handleClose,
  open,
  facilityStateId,
  setApplicationId,
  peopleList = [], //for creates
  maritalStatus, //for creates
  statusChangeModal = false, //use for status change modal vs regular form inline
  useModal = false, //Regular application form in a modal
  historicalStatusChangeEdit = false, //use for editing a status change after the fact
  refresh,

  setHasUnsavedChanges, //needed when not in modal to handle navigation prompts
  hasUnsavedChanges, //needed when not in modal to handle navigation prompts
  previouslyConfirmed, //ref needed when not in the modal to handle navigation prompts - will hide this navigation prompt if .current = true
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { companyType, companyID, firstName } = useGetUsersData();
  const {
    acApplicationTypes = [],
    applicationStatuses = [],
    facilityApplicationTypes = [],
    submissionMethodTypes = [],
    notPursuingReasons = [],
  } = useSelector(systemPersistSelectors.systemSettings, shallowEqual) || {};

  const companySettings = useSelector(uiSelectors.companySettings);
  const outsourceContacts = useSelector(uiSelectors.outsourceContacts);
  const counties = useSelector(uiSelectors.counties);

  const isEdit = !!applicationId;
  const [applicationTypesList, setApplicationTypesList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [applicantRadios, setApplicantRadios] = useState('');
  const [errors, setErrors] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [renewalDate, setRenewalDate] = useStateWithCallbackInstant(
    null,
    (date) => setShowGreatJobModal(!!date),
  );
  const [showGreatJobModal, setShowGreatJobModal] = useState(false);
  const [state, setState] = useState(initialApplicationFields);

  const [statusChangeDataState, setStatusChangeDataState] = useState(
    initialStatusChangeFields,
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const initialState = useRef({});

  const showPrompt =
    !previouslyConfirmed?.current && hasUnsavedChanges && !isFormSubmitted;

  const hasApplicationSubmissionValidation = useMemo(
    () =>
      checkHasSetting(
        companySettings,
        companySettingTypes.ApplicationSubmissionValidation,
      ),
    [companySettings],
  );
  useEffect(() => {
    if (setHasUnsavedChanges && typeof setHasUnsavedChanges === 'function') {
      setHasUnsavedChanges(
        !_isEqual({ ...state, ...statusChangeDataState }, initialState.current),
      );
    }
  });
  useEffect(() => {
    // clear the previous value on mount
    if (previouslyConfirmed) {
      previouslyConfirmed.current = false;
    }
  }, [previouslyConfirmed]);

  useEffect(() => {
    if (companyType) {
      setApplicationTypesList(
        companyType === companyTypes.Facility
          ? facilityApplicationTypes
          : acApplicationTypes,
      );
    }
  }, [acApplicationTypes, facilityApplicationTypes, companyType]);

  useEffect(() => {
    dispatch(uiActions.getMedicaidCaseworkers(facilityStateId));
    dispatch(uiActions.getCounties(facilityStateId));
  }, [dispatch, facilityStateId]);
  useEffect(() => {
    dispatch(uiActions.getJudges());
    dispatch(uiActions.getOutsourceContacts());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(peopleList)) {
      setApplicantRadios(getApplicantRadios(peopleList, maritalStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, maritalStatus]);

  //set state with application/case details
  useEffect(() => {
    if (applicationId) {
      const {
        applicationStatusChange,
        assigneeIDs,
        medicaidCaseworker,
        outsourceCompanyContact,
      } = applicationDetails;
      const caseFields = convertNullFieldsToEmptyString(applicationDetails, [
        'anticipatedMedicaidDate',
        'medicaidNeededBy',
        'startedOn',
        'renewalDate',
      ]);

      const values = {
        ...caseFields,
        medicaidCaseworkerID: medicaidCaseworker?.id || '',
        assigneeIDs,
        outsourceCompanyContactID: outsourceCompanyContact?.id || '',
      };
      let statusChangeValues = {};
      if (applicationStatusChange) {
        const { metaData, ...statusChangeFields } = applicationStatusChange;
        if (metaData) {
          const convertedStatusChangeFields = convertNullFieldsToEmptyString(
            { ...metaData, ...statusChangeFields },
            [
              'coverageBeginDate',
              'penaltyEndDate',
              'statusDate',
              'appealDueDate',
              'hearingDate',
            ],
          );
          const hasMedicalExpense =
            !!convertedStatusChangeFields.medicalExpenseAmount;
          const hasPenalty = !!convertedStatusChangeFields.penaltyAmount;

          statusChangeValues = {
            ...convertedStatusChangeFields,
            hasMedicalExpense,
            hasPenalty,
          };
          setStatusChangeDataState(statusChangeValues);
        }
      }
      setState({ ...initialApplicationFields, ...values });

      initialState.current = {
        ...initialApplicationFields,
        ...values,
        ...statusChangeValues,
      };
    } else {
      const newValues = {
        anticipatedMedicaidDate: applicationDetails.anticipatedMedicaidDate,
        medicaidNeededBy: applicationDetails.medicaidNeededBy,
        personID: peopleList[0]?.id ? peopleList[0].id + '' : null,
        startedOn: new Date(),
      };

      setState({ ...initialApplicationFields, ...newValues });

      initialState.current = {
        ...initialApplicationFields,
        ...newValues,
        ...initialStatusChangeFields,
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationDetails, applicationId]);

  useEffect(() => {
    //check that its false vs falsy - if it wasn't set yet we don't want to clear it
    if (statusChangeDataState.hasMedicalExpense === false) {
      setStatusChangeDataState((s) => ({
        ...s,
        medicalExpenseAmount: '',
        recipient: '',
      }));
    }
  }, [statusChangeDataState.hasMedicalExpense]);
  useEffect(() => {
    //check that its false vs falsy - if it wasn't set yet we don't want to clear it
    if (statusChangeDataState.hasPenalty === false) {
      setStatusChangeDataState((s) => ({
        ...s,
        penaltyAmount: '',
        penaltyEndDate: null,
      }));
    }
  }, [statusChangeDataState.hasPenalty]);

  useEffect(() => {
    if (
      state.applicationStatus === applicationStatusTypes.Preparation ||
      state.applicationStatus === applicationStatusTypes.PendingApproval ||
      state.applicationStatus === applicationStatusTypes.NotPursuing
    ) {
      //default the statusDate to current date if none is set
      setStatusChangeDataState((s) => ({
        ...s,
        statusDate: s.statusDate ?? new Date(),
      }));
    }
  }, [state.applicationStatus]);

  const handleDelete = useCallback(async () => {
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl: `/applications`,
        id: applicationId,
      }),
    );
    const { error, data } = response;
    if (!error) {
      setIsFormSubmitted(true);
      setApplicationId(''); //this will trigger clear from queryParam so it will refresh properly
      refresh(data?.residentProfileCacheID);
      handleClose();
      notify('Application Deleted');
    }
  }, [dispatch, applicationId, setApplicationId, refresh, handleClose, notify]);

  const handleDeleteStatusChange = useCallback(async () => {
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl: `/applications/${applicationId}/status-change`,
        id: statusChangeDataState.id,
      }),
    );
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
      notify('Status Deleted');
    }
  }, [
    dispatch,
    applicationId,
    refresh,
    handleClose,
    notify,
    statusChangeDataState.id,
  ]);

  const onChangeDocuments = (documents) => {
    setStatusChangeDataState((curState) => ({ ...curState, documents }));

    if (errors.documents) {
      const { isValid, errorMessage } = validateDocsRequired(documents);
      if (!isValid) {
        errors.documents = errorMessage;
      } else {
        errors.documents = null;
      }
    }
  };

  const uploadDocs = useCallback(
    async (docs) => {
      const { isValid, errorMessage } = validateDocsRequired(docs);
      if (!isValid) {
        errors.documents = errorMessage;
        return { error: true };
      }

      const { error, documents } = await uploadDocuments({
        documents: docs,
        customCategory: uploadDocumentCategories.Application,
        companyID,
        caseId,
        isDocumentsList: true,
      });

      if (error) {
        setSubmitting(false);
        notify('Error uploading document.', 'error');
        return { error: true };
      }

      return { documents };
    },
    [caseId, companyID, errors, notify],
  );

  const onHistoricalStatusChangeSave = useCallback(() => {
    return validateOnSubmit({
      values: statusChangeDataState,
      schema: statusChangeSchema,
      setErrors,
      context: {
        applicationStatus: state.applicationStatus,
        applicationType: state.applicationType,
        hasApplicationSubmissionValidation: hasApplicationSubmissionValidation,
      },
    })
      .then(async () => {
        setSubmitting(true);

        const {
          id,
          statusDate,
          hasMedicalExpense,
          hasPenalty,
          applicationStatusChangeContactIDs,
          companyContactID,
          noteText,
          documents,
          ...metaData
        } = statusChangeDataState;
        const statusChangePayload = {
          id,
          statusDate,
          noteText,
          companyContactID,
          applicationStatusChangeContactIDs,
          documents,
          metaData,
          status: state.applicationStatus,
          caseID: caseId,
        };

        if (
          hasApplicationSubmissionValidation &&
          state.applicationStatus === applicationStatusTypes.Submitted
        ) {
          const { error, documents } = await uploadDocs(
            statusChangeDataState.documents,
          );
          if (error) {
            setSubmitting(false);
            return;
          }
          statusChangePayload.documents = documents;
        }

        const response = await dispatch(
          applicationActions.upsertApplicationStatusChange({
            payload: statusChangePayload,
            applicationId,
          }),
        );
        setSubmitting(false);
        const { data } = response;
        if (data) {
          refresh(data.residentProfileCacheID);
          handleClose();
          notify('Changes Saved');
        }
      })
      .catch((e) => console.error('error ', e));
  }, [
    applicationId,
    caseId,
    dispatch,
    handleClose,
    notify,
    refresh,
    state,
    statusChangeDataState,
    hasApplicationSubmissionValidation,
    uploadDocs,
  ]);

  const onSave = useCallback(() => {
    return validateOnSubmit({
      values: { ...state, ...statusChangeDataState },
      schema,
      setErrors,
      context: {
        applicationStatus: state.applicationStatus,
        applicationType: state.applicationType,
        isPersonRequired: !applicationId,
        hasApplicationSubmissionValidation: hasApplicationSubmissionValidation,
      },
    })
      .then(async () => {
        setSubmitting(true);
        const {
          id,
          statusDate,
          hasMedicalExpense,
          hasPenalty,
          applicationStatusChangeContactIDs,
          companyContactID,
          noteText,
          documents,
          ...metaData
        } = statusChangeDataState;
        const statusChangePayload = {
          id,
          statusDate,
          noteText,
          companyContactID,
          applicationStatusChangeContactIDs,
          documents,
          metaData,
        };

        if (
          hasApplicationSubmissionValidation &&
          state.applicationStatus === applicationStatusTypes.Submitted
        ) {
          const { error, documents } = await uploadDocs(
            statusChangeDataState.documents,
          );
          if (error) {
            setSubmitting(false);
            return;
          }
          statusChangePayload.documents = documents;
        }

        const { personID, outsourceCompanyContactID, ...rest } = state;
        const personId = personID !== 'joint' ? personID : null;
        const outsourcedId =
          outsourceCompanyContactID === 'other'
            ? null
            : outsourceCompanyContactID;
        const response = await dispatch(
          applicationActions.upsertApplication({
            payload: {
              ...rest,
              caseID: caseId,
              isJoint: personID === 'joint',
              personID: personId,
              applicationID: applicationId,
              applicationStatusChange: statusChangePayload,
              outsourceCompanyContactID: outsourcedId,
            },
          }),
        );
        setSubmitting(false);
        const { data } = response;
        if (data) {
          setIsFormSubmitted(true);
          if (!isEdit && typeof setApplicationId === 'function') {
            setApplicationId(data.id);
          }
          refresh(data.residentProfileCacheID);
          if (data.status === 'Approved') {
            setRenewalDate(data.renewalDate);
          } else {
            handleClose();
            notify(isEdit ? 'Changes Saved' : 'Application created');
          }
        }
      })
      .catch((e) => console.error('error ', e));
  }, [
    applicationId,
    caseId,
    dispatch,
    handleClose,
    hasApplicationSubmissionValidation,
    notify,
    refresh,
    state,
    statusChangeDataState,
    uploadDocs,
    isEdit,
    setApplicationId,
    setRenewalDate,
  ]);

  const onChange = useCallback((event) => {
    const { value, name, type, checked } = event.target;
    const _value = type === 'checkbox' ? checked : value;
    setState((curState) => ({ ...curState, [name]: _value }));
    if (name === 'applicationStatus') {
      setStatusChangeDataState((s) => ({
        ...initialStatusChangeFields,
        id: s.id,
      })); //keep the id when switching statuses, using for editing
    }
    if (name === 'isOutsourced' && _value === false) {
      setState((s) => ({ ...s, outsourceCompanyContactID: null }));
    }
  }, []);

  const onStatusChange = useCallback((event) => {
    const { value, name, type, checked } = event.target;
    const _value = type === 'checkbox' ? checked : value;
    setStatusChangeDataState((curState) => ({ ...curState, [name]: _value }));
  }, []);
  const { color: statusColor } = getColorForApplicationStatus(
    state.applicationStatus,
  );

  const formBody = (
    <div className={classes.formPadding}>
      <Grid container className={classes.grid} spacing={4}>
        {!statusChangeModal && (
          <>
            {!isEdit &&
              Array.isArray(applicantRadios) &&
              applicantRadios.length > 0 && (
                <Grid
                  item
                  xs={12}
                  className={classes.gridModalItem}
                  style={{ marginBottom: 16 }}
                >
                  <RadioInput
                    name='personID'
                    value={state.personID}
                    onChange={onChange}
                    radios={applicantRadios}
                    labelProps={{ classes: { root: classes.pageSmallTitle } }}
                    label='Applicant'
                    schema={schema}
                    error={errors.personID}
                    required
                    context={{ isPersonRequired: state.personID !== 'joint' }}
                  />
                </Grid>
              )}
            <Grid item xs={6} className={classes.gridModalItem}>
              <SelectInput
                fullWidth
                className={classes.inputRow}
                value={state.applicationType || ''}
                name='applicationType'
                onChange={onChange}
                label='Application type'
                menuItems={applicationTypesList}
                schema={schema}
                error={errors.applicationType}
                required
              />
            </Grid>
            <Grid item xs={6} className={classes.gridModalItem}>
              <DateInput
                fullWidth
                className={classes.inputRow}
                value={state.startedOn}
                name='startedOn'
                onChange={onChange}
                label='Started on'
                schema={schema}
                error={errors.startedOn}
                isDateOnly={false}
                required
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridModalItem}>
              <DateInput
                fullWidth
                className={classes.inputRow}
                value={state.medicaidNeededBy}
                name='medicaidNeededBy'
                onChange={onChange}
                label='Medicaid needed by'
                schema={schema}
                error={errors.medicaidNeededBy}
                sx={{ width: '100%' }}
              />
            </Grid>

            <Grid item xs={6} className={classes.gridModalItem}>
              <DateInput
                fullWidth
                className={classes.inputRow}
                value={state.anticipatedMedicaidDate}
                name='anticipatedMedicaidDate'
                onChange={onChange}
                label='Anticipated Medicaid pickup'
                schema={schema}
                error={errors.anticipatedMedicaidDate}
                sx={{ width: '100%' }}
              />
            </Grid>

            <Grid item xs={6} className={classes.gridModalItem}>
              <AutocompleteInput
                fullWidth
                textFieldProps={{ label: 'County', onChange: onChange }}
                autocompleteProps={{
                  options: counties,
                  value: state.countyID,
                  name: 'countyID',
                }}
                schema={schema}
                error={errors.countyID}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridModalItem}>
              <TextInput
                fullWidth
                className={classes.inputRow}
                value={state.medicaidCaseID}
                name='medicaidCaseID'
                onChange={onChange}
                label='Case ID #'
                schema={schema}
                error={errors.medicaidCaseID}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={clsx(classes.gridModalItem, classes.helperTextInput)}
            >
              <TextInput
                fullWidth
                className={classes.inputRow}
                value={state.info}
                inputProps={{ maxLength: 200 }}
                name='info'
                onChange={onChange}
                label='Info'
                schema={schema}
                error={errors.info}
                helperText={
                  'This is a handy spot to write any important information that you want seen on the application tab'
                }
              />
            </Grid>

            <Grid item xs={6} className={classes.gridModalItem}>
              <CheckboxInput
                fullWidth
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Outsourced',
                    },
                    checkboxProps: {
                      name: 'isOutsourced',
                      checked: state.isOutsourced,
                      onChange: onChange,
                      schema: schema,
                      error: errors.isOutsourced,
                    },
                  },
                ]}
              />
            </Grid>
            {state.isOutsourced ? (
              <Grid item xs={6} className={classes.gridModalItem}>
                <SelectInput
                  fullWidth
                  className={classes.inputRow}
                  value={state.outsourceCompanyContactID || 'other'}
                  name='outsourceCompanyContactID'
                  onChange={onChange}
                  label='Outsourced Agency'
                  menuItems={[
                    { id: 'other', name: 'Other' },
                    ...outsourceContacts,
                  ]}
                  schema={schema}
                  error={errors.outsourceCompanyContactID}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={6}
                className={classes.gridModalItem}
                style={{ height: 82 }}
              />
            )}
            <Grid item xs={6} className={classes.gridModalItem}>
              <SelectInput
                fullWidth
                className={classes.inputRow}
                value={state.applicationStatus || ''}
                name='applicationStatus'
                onChange={onChange}
                label='Status'
                menuItems={applicationStatuses}
                schema={schema}
                error={errors.applicationStatus}
                required
              />
            </Grid>
          </>
        )}
        {!!state.applicationStatus &&
          isValidElement(
            statusChangeFieldsComponent[state.applicationStatus],
          ) &&
          cloneElement(statusChangeFieldsComponent[state.applicationStatus], {
            applicationStatus: state.applicationStatus,
            applicationType: state.applicationType,
            state: statusChangeDataState,
            errors,
            hasApplicationSubmissionValidation,
            onChange: onStatusChange,
            onChangeApplicationData: onChange,
            applicationState: state,
            onChangeDocuments,
            classes,
            facilityState,
            submissionMethodTypes,
            notPursuingReasons,
            showApplicationFields:
              statusChangeModal && !historicalStatusChangeEdit,
            historicalStatusChangeEdit,
          })}
      </Grid>
    </div>
  );

  return (
    <Fragment>
      {showGreatJobModal ? (
        <Dialog maxWidth='xs' fullWidth open={true} onClose={handleClose}>
          <div className={classes.greatJobModal}>
            <CheckCircleIcon className={classes.greatJobIcon} />
            <div className={classes.greatJobTitle}>Great job, {firstName}!</div>
            <div className={classes.greatJobText}>
              Your next renewal date is on {formatDate(renewalDate)}.
            </div>
          </div>
          <Divider />
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClose}
              className={classes.okButton}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : statusChangeModal || useModal ? (
        <FormModal
          handleClose={handleClose}
          handleDelete={handleDeleteStatusChange}
          isEdit={isEdit}
          onSave={
            historicalStatusChangeEdit ? onHistoricalStatusChangeSave : onSave
          }
          open={open}
          submitting={submitting}
          title={
            historicalStatusChangeEdit
              ? 'Edit status details'
              : statusChangeModal
              ? 'Update application status'
              : isEdit
              ? 'Edit application'
              : 'New application'
          }
          hideDeleteBtn={!historicalStatusChangeEdit} //delete in the modal will only be for historical status change edits
          rightSideHeader={
            statusChangeModal ? (
              <ReadOnlyComponent
                isReadOnly={historicalStatusChangeEdit}
                message={
                  'Changing the status for a previous record is not allowed.'
                }
              >
                <Select
                  variant='filled'
                  disableUnderline
                  value={state.applicationStatus || ''}
                  name='applicationStatus'
                  onChange={onChange}
                  schema={schema}
                  error={errors.applicationStatus}
                  className={classes.customStatusDropdown}
                  style={{
                    backgroundColor: statusColor,
                  }}
                >
                  {applicationStatuses.map((c, i) => (
                    <MenuItem key={i} value={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </ReadOnlyComponent>
            ) : (
              <>
                <div className={classes.flex}>
                  <MedicaidCaseworkerDisplayInput
                    caseworkerId={state.medicaidCaseworkerID}
                    onChange={onChange}
                  />
                  <AssigneeAutocompleteInput
                    caseId={caseId}
                    assigneeIDs={state.assigneeIDs}
                    onChange={onChange}
                  />
                </div>
              </>
            )
          }
        >
          {formBody}
        </FormModal>
      ) : (
        <div>
          <div
            className={clsx(
              classes.flex,
              classes.titleRow,
              classes.formPadding,
            )}
            style={{ marginBottom: isEdit ? 24 : 18 }}
          >
            <span className={classes.title}>
              {isEdit ? 'Edit application' : 'New application'}
            </span>
            <div className={classes.flex}>
              <MedicaidCaseworkerDisplayInput
                caseworkerId={state.medicaidCaseworkerID}
                onChange={onChange}
              />
              <AssigneeAutocompleteInput
                caseId={caseId}
                assigneeIDs={state.assigneeIDs}
                onChange={onChange}
              />
            </div>
          </div>
          {formBody}
          {submitting && <Loader />}
          <DialogActions
            style={{
              padding: '24px 32px 24px 24px',
              justifyContent: 'space-between',
            }}
          >
            {isEdit ? (
              <Button
                className={classes.deleteBtn}
                onClick={() => setShowDeleteConfirm(true)}
                disabled={submitting}
              >
                Delete
              </Button>
            ) : (
              <div /> //style placeholder
            )}
            <div>
              <Button
                color='primary'
                variant='contained'
                className={classes.cancelBtn}
                onClick={handleClose}
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                color='primary'
                variant='contained'
                onClick={onSave}
                style={{
                  marginLeft: 20,
                  width: 90,
                }}
                disabled={submitting}
              >
                Save
              </Button>
            </div>
          </DialogActions>
          {showDeleteConfirm && (
            <Dialog
              maxWidth='xs'
              fullWidth
              open={true}
              onClose={() => setShowDeleteConfirm(false)}
            >
              <DeleteContent
                handleClose={() => setShowDeleteConfirm(false)}
                title={'Edit application'}
                handleDelete={handleDelete}
              />
            </Dialog>
          )}
        </div>
      )}
      <Prompt
        message='You have unsaved changes that will be lost.'
        when={showPrompt}
      />
    </Fragment>
  );
}

function getApplicantRadios(peopleList, maritalStatus) {
  if (!peopleList || peopleList.length < 1) return [];
  const list = peopleList.map((r) => {
    const { id, name } = r;
    return { label: name, value: id + '' };
  });
  if (peopleList.length > 1) {
    list.push({ label: 'Joint Application', value: 'joint' });
  }
  if (maritalStatus !== 'Married') {
    return [list[0]];
  }
  return list;
}
