import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  scrollableContent: {
    overflowY: 'auto',
    width: '100%',
    maxHeight: 'calc(100vh - 244px)',
  },
  content: {
    // paddingTop: 10,
  },
  sectionHeaderApplicant: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  headerDivider: {
    color: '#E5E7F5',
  },
  cancelBtn: {
    backgroundColor: '#E5E7F5',
    '&:hover': {
      backgroundColor: '#E5E7F5',
    },
    color: theme.palette.text.primary,
  },
  deleteBtn: {
    color: theme.palette.primary.light,
    textTransform: 'none',
    fontWeight: 500,
  },
  confirmDeleteBtn: {
    backgroundColor: '#F91429',
    '&:hover': {
      backgroundColor: '#F91429',
    },
    color: '#FFFFFF',
  },
  errorMessage: {
    color: '#f44336',
    fontSize: 14,
    position: 'absolute',
    right: 32,
  },
}));
