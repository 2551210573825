import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { ArrowDropDownIcon, CancelIcon, SearchIcon } from '../../../../themes';
import { uiActions, uiSelectors } from '../../../../state';
import { useStyles } from './listFilters.styles';
import _isEqual from 'lodash.isequal';
import FilterBase from './FilterBase';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FlexCenter } from '../../../../components';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function FilterOption(props) {
  const {
    active,
    menuItems,
    selectedMenuItems,
    value,
    label,
    name,
    onChange,
    multiple = false,
    selectMenu = false,
    displayContentsFunc = null,
    displayContentsParam = null,
    checkbox = false,
    closeOnChange = false,
    paperStyles = {},
    onClear,
    isMoreButton = false,
    searchPlaceholder,
    twoInOne = false,
    filter2 = {},
    filter2Value,
    groupedOptionsLabel = 'All filters',
    customButtonStyles = null,
    valueFormatter,
    renderButton = null,
    disableAutoOpen = false,
  } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingValues, setPendingValues] = useState([]); //used only for the autocomplete - multi type
  const [curHoverOption, setCurHoverOption] = useState({});

  const open = Boolean(anchorEl);
  const classes = useStyles({ open, isMoreButton });
  const currentListFilter = useSelector(uiSelectors.currentListFilter);
  const buttonRef = useRef();

  useEffect(() => {
    if (currentListFilter === name && !disableAutoOpen) {
      setAnchorEl(buttonRef.current);
    }
  }, [currentListFilter, setAnchorEl, buttonRef, name, disableAutoOpen]);

  //for multi type: sort by selected and add 'selected' property to use groupby
  const sortedMenuItems = useMemo(
    () =>
      menuItems &&
      Array.isArray(menuItems) &&
      menuItems
        .map((o) => {
          const selected = selectedMenuItems.some(({ id }) => o.id === id);
          return { ...o, selected };
        })
        .sort((o1, o2) => {
          return o2.selected - o1.selected;
        }),
    [menuItems, selectedMenuItems],
  );
  //open the popper for the filter options
  const handleButtonClick = (event) => {
    setPendingValues(sortedMenuItems?.filter((o) => o.selected));
    setAnchorEl(buttonRef.current);
  };

  const handleFilterChange = useCallback(
    (selectedValues) => {
      const selectedValuesIDs = selectedValues.map((i) => i.id);
      onChange(selectedValuesIDs);
    },
    [onChange],
  );

  //handles closing the autocomplete and the popper - only if they click out of it, applies the filters
  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }

    //if closeOnChange, the onchange was already handled, we just want to close now
    if (!closeOnChange) {
      handleFilterChange(pendingValues);
    }

    if (currentListFilter === name) {
      dispatch(uiActions.setCurrentListFilter(''));
    }
    setAnchorEl(null);
  };

  const {
    display: filter2Display,
    name: filter2Name,
    label: filter2Label,
    valueFormatter: filter2ValueFormatter,
  } = filter2;

  const firstValue =
    value ??
    (Array.isArray(selectedMenuItems)
      ? selectedMenuItems[0]
      : selectedMenuItems);

  const formatValue = (value, formatter) => {
    if (formatter && typeof formatter === 'function') {
      return formatter(value);
    }
    return value;
  };

  const calculateDisplayValue = () => {
    if (
      checkbox ||
      isMoreButton ||
      (renderButton && typeof renderButton === 'function')
    ) {
      //checkbox filter: they can't be docked, once added its auto on. to turn off, clear it; no display label, no dropdown
      //more button doesnt have an additional display
      return '';
    }

    if (twoInOne) {
      const display = `${
        value ? formatValue(value, valueFormatter) : `All`
      } - ${
        filter2Value ? formatValue(filter2Value, filter2ValueFormatter) : `All`
      }`;
      return value || filter2Value ? display : 'All';
    }

    //all other filter types
    if (firstValue || (selectMenu && firstValue === false)) {
      return typeof firstValue !== 'object'
        ? formatValue(
            menuItems?.find((f) => f.id === firstValue)?.name ?? firstValue,
            valueFormatter,
          )
        : formatValue(
            menuItems?.find((f) => f.id === firstValue.id)?.name,
            valueFormatter,
          ) +
            `${
              selectedMenuItems?.length > 1
                ? ` + ${selectedMenuItems.length - 1}`
                : ``
            }`;
    }
    return 'All'; //no filter set
  };
  const displayValue = calculateDisplayValue();

  const { onChange: displayOnChange, ...restDisplayContentsParam } =
    displayContentsParam || {};

  const handleAutocompleteChange = useCallback(
    (event, newValue) => {
      if (closeOnChange) {
        handleFilterChange(newValue);
        setPendingValues(newValue);
      } else {
        setPendingValues(newValue);
      }
    },
    [closeOnChange, handleFilterChange],
  );
  const onSelectOnly = (value) => {
    handleAutocompleteChange(null, [value]);
    if (closeOnChange) {
      handleClose(null, null);
    }
  };

  const [twoInOneState, setTwoInOneState] = useState({
    val1: value,
    val2: filter2Value,
  });
  //reset when value changes - handle clear all
  useEffect(() => {
    setTwoInOneState({ val1: value, val2: filter2Value });
  }, [value, filter2Value, setTwoInOneState]);
  const handleTwoInOneChange = useCallback(
    (twoInOneState) => {
      const { val1, val2 } = twoInOneState;
      onChange(val1, { [filter2Name]: val2 });
    },
    [onChange, filter2Name],
  );

  return checkbox ? (
    <Box
      sx={{
        border: active ? '1px solid #096EF8' : '1px solid #B9BDD4',
        borderRadius: '9px',
        paddingRight: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={onChange}
            name={name}
            value={value}
          />
        }
        label={label}
        slotProps={{
          typography: {
            // variant: small button
            fontSize: '13px',
            fontWeight: 500,
          },
        }}
        sx={{
          padding: '0 8px',
          margin: 0,
          '& span:first-of-type': {
            display: 'none',
          },
        }}
      />
      {onClear && (
        <CancelIcon
          fontSize='small'
          className={classes.cancelIcon}
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        />
      )}
    </Box>
  ) : (
    <Fragment>
      {renderButton && typeof renderButton === 'function' ? (
        renderButton({
          onClick: handleButtonClick,
          ref: buttonRef,
        })
      ) : (
        <Button
          onClick={handleButtonClick}
          className={clsx(
            classes.residentsFilterPopperButtons,
            onClear ? classes.filterOptionClearable : null,
            customButtonStyles,
          )}
          ref={buttonRef}
          sx={{
            border: active ? '1px solid #096EF8' : '1px solid #B9BDD4',
          }}
        >
          {label}
          {`${label.length > 0 && displayValue.length > 0 ? `: ` : ''}`}
          {displayValue
            ? `${
                displayValue.length + label.length > 38 && !onClear
                  ? `${displayValue.slice(0, 38 - label.length)}...`
                  : `${displayValue}`
              }`
            : ''}
          <ArrowDropDownIcon />
          {onClear && (
            <CancelIcon
              fontSize='small'
              className={classes.cancelIcon}
              onClick={(e) => {
                e.stopPropagation();
                onClear();
              }}
            />
          )}
        </Button>
      )}

      {selectMenu ? (
        /* regular select for non multi-option*/
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleClose}
          classes={{ paper: classes.menuPaper }}
        >
          {menuItems.map((a, index) => {
            const { id, name } = a;
            const selected = id === (firstValue?.id ?? firstValue); //here only one can be selected

            return (
              <MenuItem
                className={classes.option}
                key={index}
                style={selected ? { backgroundColor: '#00000014' } : null}
                value={id}
                onClick={(event) => {
                  closeOnChange && handleClose(null, null); //close the popup
                  onChange(id); //set the filter
                }}
              >
                {name}
              </MenuItem>
            );
          })}
        </Menu>
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={!multiple ? handleClose : undefined}
          PaperProps={{
            overflow: 'auto',
            sx: { width: 250 },
          }}
        >
          <Paper style={paperStyles}>
            {multiple ? (
              /* autocomplete for a multi-option filter select */
              <Autocomplete
                disableClearable
                classes={{
                  paper: classes.paper,
                  option: classes.option,
                  popperDisablePortal: classes.popperDisablePortal,
                }}
                disableCloseOnSelect={!closeOnChange}
                disablePortal
                fullWidth
                getOptionLabel={(option) => option.name}
                // sort by selected first
                groupBy={(option) => (option.selected ? 'selected' : 'all')}
                isOptionEqualToValue={(option, value) => {
                  //ignore the 'selected' flag we added when comparing
                  const { selected: selectedOpt, ...restOption } = option;
                  const { selected: selectedVal, ...restValue } = value;
                  return _isEqual({ ...restOption }, { ...restValue });
                }}
                multiple
                onChange={handleAutocompleteChange}
                onClose={handleClose}
                onHighlightChange={(e, o, r) => {
                  setCurHoverOption(o);
                }}
                open
                options={sortedMenuItems}
                renderGroup={(group) => {
                  if (group.group === 'selected') {
                    //selected ones at least 2, include the clear selected items link
                    return (
                      <Fragment key={group.group}>
                        {pendingValues.length > 1 && (
                          <div
                            className={classes.clearSelectedItemsButton}
                            onClick={() => {
                              handleAutocompleteChange(null, []);
                              closeOnChange && handleClose(null, null);
                            }}
                          >
                            Clear selected items
                          </div>
                        )}
                        {group.children}
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={group.group}>
                        <div className={classes.allItemsLabelWrapper}>
                          <div className={classes.allItemsLabel}>
                            {groupedOptionsLabel}
                          </div>
                          {!isMoreButton &&
                            pendingValues.length < sortedMenuItems.length && (
                              <div
                                className={classes.clearSelectedItemsButton}
                                onClick={() => {
                                  handleAutocompleteChange(
                                    null,
                                    sortedMenuItems,
                                  );
                                  closeOnChange && handleClose(null, null);
                                }}
                              >
                                Select All
                              </div>
                            )}
                        </div>
                        {group.children}
                      </Fragment>
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    ref={params.InputProps.ref}
                    InputProps={{
                      inputProps: { ...params.inputProps },
                      endAdornment: <SearchIcon />,
                    }}
                    autoFocus
                    fullWidth
                    placeholder={searchPlaceholder || 'Find filters...'}
                    sx={{
                      borderBottom: '1px solid #dfe2e5',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const showSelectOnly =
                    selected &&
                    pendingValues.length > 1 &&
                    _isEqual(curHoverOption, option);
                  return (
                    <li
                      {...props}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                      key={option.id}
                    >
                      <FlexCenter gap={1} flex={1}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        <Typography variant='caption'>{option.name}</Typography>
                      </FlexCenter>
                      {showSelectOnly && (
                        <div className={classes.selectOnlyLink}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              onSelectOnly(option);
                            }}
                          >
                            only
                          </span>
                        </div>
                      )}
                    </li>
                  );
                }}
                renderTags={() => null}
                sx={{ width: 320, maxWidth: '100%' }}
                value={pendingValues}
              />
            ) : twoInOne ? (
              <FilterBase
                content={
                  <div>
                    {!!displayContentsFunc &&
                      typeof displayContentsFunc === 'function' &&
                      displayContentsFunc({
                        onChange: (val) =>
                          setTwoInOneState((prev) => ({
                            ...prev,
                            val1: val,
                          })),
                        label,
                        name,
                        value: twoInOneState.val1,
                      })}

                    {!!filter2Display &&
                      typeof filter2Display === 'function' &&
                      filter2Display({
                        onChange: (val) =>
                          setTwoInOneState((prev) => ({
                            ...prev,
                            val2: val,
                          })),
                        label: filter2Label,
                        value: twoInOneState.val2,
                        filter2Name,
                      })}
                  </div>
                }
                handleClose={handleClose}
                onChange={() => {
                  handleTwoInOneChange(twoInOneState);
                  closeOnChange && handleClose();
                }}
              />
            ) : /**any other kind of filter - just render the contents */
            !!displayContentsFunc &&
              typeof displayContentsFunc === 'function' ? (
              displayContentsFunc({
                onChange: (val) => {
                  displayOnChange(val);
                  closeOnChange && handleClose();
                },
                ...restDisplayContentsParam,
                handleClose,
              })
            ) : null}
          </Paper>
        </Popover>
      )}
    </Fragment>
  );
}
