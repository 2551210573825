import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetQueryParams,
  useGetUsersData,
  useIsMobile,
  useIsSmallTablet,
  useIsTablet,
  useLoader,
  useNotify,
} from '../../hooks';

import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import {
  CaseTagIcon,
  ContentBox,
  FacilitiesFilterOption,
  FlexBetween,
  FlexCenter,
  FlexColumn,
  NavButton,
  NavButtonProps,
  NoContent,
} from '../../components';
import { ActivityFeed } from '../../components/overlay/activity/ActivityFeed';

import Calendar from './calendar/Calendar';

import {
  analyticsActions,
  uiActions,
  ResidentCountResult,
  CaseTagCountDetail,
  authPersistSelectors,
} from '../../state';
import { CalendarIcon, InfoOutlinedIcon, RefreshIcon } from '../../themes';
import {
  Routes,
  generatePath,
  pluralize,
  residentStatusTypes,
} from '../../lib';

import { ResidentListTypes } from './ListViews/helpers/ResidentListConfig';
import { ListQueryParams } from './ListViews/helpers/SharedListConfig';
import TasksAssignedToMeSwitch from './calendar/components/TasksAssignedToMeSwitch';
import { useCalendarQueryParams } from './calendar/utils';

const gapSize = '24px';

const ContainerID = 'dashboard';

export default function NewDashboardPage() {
  const dispatch = useDispatch();
  const notify = useNotify();
  const setLoader = useLoader();
  const theme = useTheme();

  const isSmallTablet = useIsSmallTablet();
  const isMobile = useIsMobile();

  const isApplicationCompany = useSelector(
    authPersistSelectors.isApplicationCompany,
  );

  const allQueryParams = useGetQueryParams();
  const { assignedToMe, selectedFacilityIds } = useCalendarQueryParams();

  // we need to calculate the height of the right side of the screen based on the painted height of the left
  const leftSideRef = useRef<HTMLDivElement>(null);

  const [residentCounts, setResidentCounts] =
    useState<ResidentCountResult | null>(null);

  // we want to set the height of the right side of the screen (activity feed) to match the height of the left side
  // render the initial height off the screen so it won't be so noticeable when the box repaints
  const [rightSideHeight, setRightSideHeight] = useState<number>(700);

  useEffect(() => {
    (async function loadResidentCounts() {
      setLoader(true);
      // @ts-expect-error // need correct typing for requests
      const { error, data } = await dispatch(
        analyticsActions.getResidentCounts(selectedFacilityIds, assignedToMe),
      );
      if (error) {
        setResidentCounts(null);
        notify('Something went wrong, please try again later', 'error');
      } else {
        setResidentCounts(data);
      }
      setLoader(false);
    })();
  }, [selectedFacilityIds, assignedToMe, dispatch, notify, setLoader]);

  useEffect(() => {
    // need to wait until the data is loaded before checking the height
    // because the height of the resident tags section can vary
    if (!!residentCounts) {
      // using a timeout because this often runs before the tags section is finished painting (even when using useLayoutEffect)
      setTimeout(() => {
        if (leftSideRef.current) {
          const { height } = leftSideRef.current.getBoundingClientRect();
          setRightSideHeight(height);
        }
      }, 1000);
    }
  }, [residentCounts]);

  const generateLinkToResidentList = useCallback(
    (queryParams: Record<string, any>) =>
      generatePath(
        Routes.residentsList.path,
        {},
        {
          [ListQueryParams.listType]: ResidentListTypes.All,
          [ListQueryParams.facilityIds]: selectedFacilityIds,
          ...queryParams,
        },
      ),
    [selectedFacilityIds],
  );

  if (!residentCounts) return null;

  return (
    <Box>
      <Header
        isApplicationCompany={isApplicationCompany}
        residentCounts={residentCounts}
        selectedFacilityIds={selectedFacilityIds}
        generateLinkToResidentList={generateLinkToResidentList}
      />

      {/* content */}
      <Box
        display='flex'
        flexDirection={isSmallTablet || isMobile ? 'column' : 'row'}
        gap={gapSize}
      >
        {/* left side */}
        <FlexColumn flexGrow={1} gap={gapSize} ref={leftSideRef}>
          {/* calendar */}
          <ContentBox>
            <FlexBetween mb='16px'>
              <SectionHeader
                title1='upcoming'
                title2='dates'
                color={theme.palette.error.light}
              />
              <Box display='flex' gap='4px'>
                <TasksAssignedToMeSwitch />
                <Tooltip title='Open full page calendar'>
                  <IconButton
                    component={Link}
                    to={generatePath(
                      Routes.calendar.path,
                      {},
                      { ...allQueryParams },
                    )}
                  >
                    <CalendarIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Box>
            </FlexBetween>
            <Calendar
              onlyShowAssignedToMe={assignedToMe}
              overdueTasksCount={residentCounts.overdueTasksCount}
              selectedFacilityIds={selectedFacilityIds}
            />
          </ContentBox>

          {/* resident plans */}
          {!isApplicationCompany && (
            <ContentBox
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              gap={gapSize}
            >
              <ResidentPlansResidentsListButton
                labelCount={residentCounts.newAdmitCount}
                labelText={`New ${pluralize(
                  'admission',
                  residentCounts.newAdmitCount,
                )}`}
                link={generateLinkToResidentList({
                  [ListQueryParams.caseStatuses]:
                    residentStatusTypes.NewAdmission,
                })}
                alwaysShowArrow
                styles={{ backgroundColor: theme.palette.primary.selected }}
              />
              <ResidentPlansResidentsListButton
                labelCount={residentCounts.skilledNursingCount}
                labelText={`Skilled ${pluralize(
                  'resident',
                  residentCounts.skilledNursingCount,
                )}`}
                link={generateLinkToResidentList({
                  [ListQueryParams.isSkilledNursing]: true,
                })}
              />
              <ResidentPlansResidentsListButton
                labelCount={residentCounts.pendingCount}
                labelText={`Pending ${pluralize(
                  'resident',
                  residentCounts.pendingCount,
                )}`}
                link={generateLinkToResidentList({
                  [ListQueryParams.hasPending]: true,
                })}
              />
              <ResidentPlansResidentsListButton
                labelCount={residentCounts.bleedingCount}
                labelText={`Bleeding ${pluralize(
                  'resident',
                  residentCounts.bleedingCount,
                )}`}
                link={generateLinkToResidentList({
                  [ListQueryParams.isBleeding]: true,
                })}
              />
            </ContentBox>
          )}

          {/* workflow summaries - TODO implement */}
          {/* <ContentBox>
            <SectionHeader
              title1='workflow'
              title2='summaries'
              color={theme.palette.info.main}
            />
            <Box
              display='grid'
              gridTemplateColumns={`repeat(3, 1fr)`}
            >
              <WorkflowsResidentsListItem
                workflowName='Application'
                inProgressCount={16}
                neededCount={4}
                infoText={
                  <Box display='block'>
                    <Typography variant='caption'>10 submitted</Typography>
                    <Typography variant='caption'>5 pending approval</Typography>
                  </Box>
                }
              />
              <WorkflowsResidentsListItem
                workflowName='LOC'
                inProgressCount={16}
                neededCount={4}
              />
              <WorkflowsResidentsListItem
                workflowName='PASSR'
                inProgressCount={16}
              />
              <WorkflowsResidentsListItem
                workflowName='Resident screening'
                neededCount={4}
              />
            </Box>
          </ContentBox> */}

          {/* resident tags */}
          <ContentBox>
            <SectionHeader
              title1='resident'
              title2='tags'
              color={theme.palette.success.main}
            />

            {!residentCounts.tagCounts.length ? (
              <NoContent
                titleText="We haven't found any tagged residents"
                captionText='Check your company settings or start tagging some folks.'
                imageLocation='/images/no-resident-tags.svg'
                marginTop='24px'
              />
            ) : (
              <Box
                display='grid'
                gridTemplateColumns={`repeat(${isMobile ? 2 : 3}, 1fr)`}
                columnGap='12px'
              >
                {residentCounts.tagCounts.map((t, i) => (
                  <TagsResidentsListButton
                    key={i}
                    tagCountDetails={t}
                    generateLinkToResidentList={generateLinkToResidentList}
                  />
                ))}
              </Box>
            )}
          </ContentBox>
        </FlexColumn>

        {/* right side */}
        <ContentBox
          minWidth={isMobile ? '100%' : '320px'}
          maxWidth={isMobile ? '100%' : '320px'}
          height={rightSideHeight}
          paddingX='0px !important'
        >
          <FlexBetween paddingX={2.5}>
            {/* restore the padding that was removed above, but only for the header */}
            <SectionHeader
              title1='activity'
              title2='feed'
              color={theme.palette.primary.main}
            />
            <IconButton
              onClick={() => dispatch(uiActions.setRefreshActivityFeed(true))}
            >
              <RefreshIcon />
            </IconButton>
          </FlexBetween>
          <Divider sx={{ marginTop: '16px' }} />
          <Box
            id={ContainerID}
            height='calc(100% - 72px)'
            overflow='auto'
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <ActivityFeed
              facilityIDs={selectedFacilityIds}
              containerId={ContainerID}
            />
          </Box>
        </ContentBox>
      </Box>
    </Box>
  );
}

type HeaderProps = {
  isApplicationCompany: boolean;
  residentCounts: ResidentCountResult;
  selectedFacilityIds: string[];
  generateLinkToResidentList: GenerateLinkToResidentListType;
};
const Header = ({
  isApplicationCompany,
  residentCounts,
  generateLinkToResidentList,
}: HeaderProps) => {
  const dispatch = useDispatch();

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const { firstName } = useGetUsersData();

  const handleSwitchToLegacyDashboard = useCallback(
    () => dispatch(uiActions.setIsNewDashboardOn(false)),
    [dispatch],
  );

  const greeting = <Typography variant='h4'>Hey, {firstName}</Typography>;
  const tagline = <Typography>You've got work. Let's get cracking.</Typography>;
  const legacyDashboardButton = (
    <Button
      variant='outlined'
      sx={{
        color: 'primary.main',
        borderColor: 'primary.main',
      }}
      onClick={handleSwitchToLegacyDashboard}
    >
      Switch to legacy dashboard
    </Button>
  );
  const buttonRow = (
    <Box
      display='flex'
      flexDirection={isMobile ? 'column' : 'row'}
      gap={isMobile ? '8px' : '16px'}
      justifyContent='right' // not relevant for mobile view
    >
      {!isApplicationCompany && (
        <>
          <HeaderResidentsListButton
            label={`${residentCounts.activeCount} ${pluralize(
              'resident',
              residentCounts.activeCount,
            )} in house`}
            link={generateLinkToResidentList({
              [ListQueryParams.isResidentActive]: true,
            })}
          />
          <HeaderResidentsListButton
            icon={<InfoOutlinedIcon color='error' />}
            label={`${residentCounts.missingCoverageCount} missing coverage`}
            link={generateLinkToResidentList({
              [ListQueryParams.isMissingCoverage]: true,
            })}
          />
        </>
      )}
      <FacilitiesFilterOption />
    </Box>
  );

  if (isMobile) {
    return (
      <FlexColumn rowGap='8px' pb={gapSize}>
        {greeting}
        {tagline}
        {legacyDashboardButton}
        {buttonRow}
      </FlexColumn>
    );
  }

  if (isTablet) {
    return (
      <Box pb={gapSize}>
        <FlexColumn rowGap='8px' pb={gapSize}>
          <FlexCenter gap={gapSize}>
            {greeting}
            {legacyDashboardButton}
          </FlexCenter>
          {tagline}
        </FlexColumn>
        {buttonRow}
      </Box>
    );
  }

  return (
    <FlexBetween pb={gapSize}>
      <FlexColumn rowGap='8px'>
        <FlexCenter gap={gapSize}>
          {greeting}
          {legacyDashboardButton}
        </FlexCenter>
        {tagline}
      </FlexColumn>
      {buttonRow}
    </FlexBetween>
  );
};

const SectionHeader = memo(
  ({
    title1,
    title2,
    color,
  }: {
    title1: string;
    title2: string;
    color: string;
  }) => (
    <Box display='flex' alignItems='baseline'>
      <Typography component='span' fontSize='24px' fontWeight='bold'>
        {title1}
      </Typography>
      <Typography component='span' fontSize='24px' fontWeight={200}>
        {title2}
      </Typography>
      {/* @ts-ignore // we want an empty Box here */}
      <Box height={6} width={6} backgroundColor={color} borderRadius='1px' />
    </Box>
  ),
);

const HeaderResidentsListButton = (props: NavButtonProps) => {
  return <NavButton variant='outlined' alwaysShowArrow {...props} />;
};

type ResidentPlansResidentsListButtonProps = {
  labelCount: number;
  labelText: string;
  styles?: Record<string, any>;
} & NavButtonProps;
const ResidentPlansResidentsListButton = (
  props: ResidentPlansResidentsListButtonProps,
) => {
  const { labelCount, labelText, styles, ...navButtonProps } = props;
  const label = (
    <FlexCenter>
      <Typography component='span' fontSize='18px' fontWeight={700} pr='12px'>
        {labelCount}
      </Typography>
      <Typography component='span' variant='caption'>
        {labelText}
      </Typography>
    </FlexCenter>
  );
  const localStyles = {
    borderRadius: '9px',
    justifyContent: 'space-between',
    padding: '12px',
    width: '100%',
    ...styles,
  };
  return <NavButton label={label} styles={localStyles} {...navButtonProps} />;
};

// TODO implement workflow summaries section
// const WorkflowsResidentsListItem = (props) => {
//   const { workflowName, inProgressCount, neededCount, infoText } = props;
//   const theme = useTheme();

//   return (
//     <Box height='90px' padding={2}>
//       <Typography variant='subtitle2'>{workflowName}</Typography>
//       {inProgressCount && (
//         <FlexCenter>
//           <WorkflowSummaryDetail label={`${inProgressCount} in progress`} />
//           {infoText && <InfoTooltip text={infoText} />}
//         </FlexCenter>
//       )}
//       {neededCount && (
//         <WorkflowSummaryDetail
//           label={`${neededCount} needed`}
//           color={theme.palette.error.main}
//         />
//       )}
//     </Box>
//   );
// };

// const WorkflowSummaryDetail = ({ label, link, color }) => {
//   const theme = useTheme();
//   return (
//     <Button
//       sx={{
//         justifyContent: 'flex-start',
//         color,
//         padding: 0,
//         '&:hover': {
//           color: theme.palette.primary.main,
//           backgroundColor: 'transparent',
//         },
//       }}
//       variant='text'
//       component={Link} // note this is Link from react-router-dom
//       to={link}
//     >
//       <Typography variant='caption'>{label}</Typography>
//     </Button>
//   );
// };

// const InfoTooltip = ({ text }: { text: string }) => (
//   <Tooltip title={text} placement='top-end' sx={{ ml: '4px' }}>
//     <InfoOutlinedIcon fontSize='tiny' />
//   </Tooltip>
// );

type TagsResidentsListButtonProps = {
  tagCountDetails: CaseTagCountDetail;
  generateLinkToResidentList: GenerateLinkToResidentListType;
};
const TagsResidentsListButton = (props: TagsResidentsListButtonProps) => {
  const {
    tagCountDetails: {
      tagCount,
      tag,
      tag: { name, companyCaseTagID },
    },
    generateLinkToResidentList,
  } = props;

  const styles = {
    padding: '4px 8px',
    width: '100%',
    justifyContent: 'flex-start', // override default NavButton mobile style
  };

  const label = (
    <Typography variant='body0'>
      {tagCount} {name}
    </Typography>
  );

  return (
    <NavButton
      label={label}
      icon={<CaseTagIcon caseTag={tag} />}
      link={generateLinkToResidentList({
        [ListQueryParams.caseTags]: companyCaseTagID,
      })}
      styles={styles}
    />
  );
};

type GenerateLinkToResidentListType = (arg0: Record<string, any>) => string;
