import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { TextField } from '@mui/material';
import { reach } from 'yup';
import NumberFormat from 'react-number-format';
import { useStyles } from './inputs.styles';
import { formatMedicareNumber } from '../../../lib';

/**
 * Material-ui TextField with validation TextInput
 * @param {import("@mui/material/TextField").TextFieldProps & textInputProps} props
 */
export function TextInput(props) {
  let classes = useStyles();
  if (props.classes) {
    classes = props.classes;
  }
  const {
    multiline,
    rows,
    schema,
    context,
    nestedKey,
    className,
    placeholder,
    onChange,
    onBlur,
    fullWidth,
    error,
    format,
    addHelperTextStyle = true,
    inputProps = {},
    endAdornment,
    helperText,
    ...otherProps
  } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setErrorMessage(error);
    if (error) {
      setIsDirty(true);
    }
  }, [error]);

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleBlur = useCallback(
    (event) => {
      typeof onBlur === 'function' && onBlur(event);
      const { name, value } = event.target;
      if (schema && !errorMessage) {
        setIsDirty(true);
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, errorMessage, nestedKey, onBlur, schema],
  );

  const handleChange = useCallback(
    (event) => {
      typeof onChange === 'function' && onChange(event);
      const { name, value } = event.target;
      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(false))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, schema],
  );

  return (
    <TextField
      variant='outlined'
      className={clsx({ [classes.root]: !fullWidth }, className)}
      fullWidth={fullWidth}
      onBlur={handleBlur}
      onChange={handleChange}
      multiline={multiline}
      minRows={rows}
      error={!!errorMessage || (errorMessage !== false && !!error)}
      helperText={
        helperText ?? (errorMessage !== false ? errorMessage || error : ' ')
      }
      FormHelperTextProps={{
        classes: { root: clsx({ [classes.helperText]: addHelperTextStyle }) },
        children: ' ',
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
          notchedOutline: classes.notchedOutline,
        },
        inputComponent: format
          ? format === 'medicare'
            ? MedicareFormatter
            : NumberFormatter
          : undefined,
        inputProps: { format, ...inputProps },
        endAdornment: endAdornment,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
        },
      }}
      {...otherProps}
    />
  );
}

const NumberFormatter = React.forwardRef(function NumberFormatter(props, ref) {
  const { onChange, format, ...other } = props;
  const [formatInput, setFormatInput] = useState({});

  useEffect(() => {
    const formatType = formatTypes[format];
    if (formatType) {
      setFormatInput(formatType);
    }
  }, [format]);

  return (
    <div>
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        {...formatInput}
      />
    </div>
  );
});

const MedicareFormatter = React.forwardRef(function MedicareFormatter(
  props,
  ref,
) {
  const { format, onChange, ...other } = props;

  useEffect(() => {
    const { value, name } = props;
    if (value?.length < 13) {
      handleInput({
        target: {
          name,
          value,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleInput = (e) => {
    // format to medicare format
    const formattedMedicareNumber = formatMedicareNumber(e.target.value);
    onChange({
      target: {
        name: props.name,
        value: formattedMedicareNumber,
      },
    });
  };

  return <input {...other} ref={ref} onChange={(e) => handleInput(e)} />;
});

const formatTypes = {
  phone: {
    format: '(###) ###-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
  ssn: {
    format: '###-##-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
  medicare: {
    format: '####-###-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
  currency: {
    prefix: '$',
    thousandSeparator: true,
  },
  percent: { suffix: '%' },
};

// #region Typedefs
/** @typedef {object} textInputProps
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {string} error The error message when failing validation on submit
 * @property {boolean} [addHelperTextStyle] Boolean to add position absolute to helper text. Defaults to true.
 * @property {string} helperText helper text to display. When setting this, `error` message is ignored.
 */
// #endregion
