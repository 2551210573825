import * as yup from 'yup';
import {
  applicationStatusTypes,
  applicationTypes,
  coveragePeriodPositions,
  coverageTypes,
} from '../../lib';

const statusChangeSchema = {
  submissionMethodType: yup
    .string('This field must be a string')
    .when(['$applicationStatus', '$hasApplicationSubmissionValidation'], {
      is: (applicationStatus, hasApplicationSubmissionValidation) =>
        hasApplicationSubmissionValidation &&
        applicationStatus === applicationStatusTypes.Submitted,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  coverageBeginDate: yup
    .date()
    .typeError('Invalid Date')
    .when(['$applicationStatus', '$applicationType'], {
      is: (applicationStatus, applicationType) =>
        applicationType !== applicationTypes.Renewal &&
        (applicationStatus === applicationStatusTypes.Approved ||
          applicationStatus === applicationStatusTypes.AdjustmentNeeded),
      then: (s) => s.required('Required'),
    })
    .nullable(),
  medicaidCostShare: yup
    .string('This field must be a string')
    .when('$applicationStatus', (applicationStatus, schema) => {
      return applicationStatus === applicationStatusTypes.Approved ||
        applicationStatus === applicationStatusTypes.AdjustmentNeeded
        ? schema.required('Required')
        : schema;
    })
    .nullable(),
  penaltyAmount: yup
    .string('This field must be a string')
    .when('hasPenalty', {
      is: true,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  penaltyEndDate: yup
    .string('This field must be a string')
    .when('hasPenalty', {
      is: true,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  medicalExpenseAmount: yup
    .string('This field must be a string')
    .when('hasMedicalExpense', {
      is: true,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  recipient: yup
    .string('This field must be a string')
    .when('hasMedicalExpense', {
      is: true,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  hearingDate: yup.date().typeError('Invalid Date').nullable(),
  appealDueDate: yup.date().typeError('Invalid Date').nullable(),
  noteText: yup.string('This field must be a string').nullable(),
  statusDate: yup.date().typeError('invalid date').required(),
  companyContactID: yup.string().nullable(),
  applicationStatusChangeContactIDs: yup
    .array(yup.string())
    .typeError('must be an array')
    .nullable(),
  notPursuingReason: yup
    .string('This field must be a string')
    .when(['$applicationStatus'], {
      is: (applicationStatus) =>
        applicationStatus === applicationStatusTypes.NotPursuing,
      then: (s) => s.required('Required'),
    })
    .nullable(),
};
export const applicationStatusChangeSchema = yup.object().shape({
  ...statusChangeSchema,
});

export const addUserSchema = yup.object().shape({
  email: yup
    .string('Email must be a string')
    .email('Invalid Email')
    .nullable()
    .when('$isEdit', {
      is: true,
      then: (f) => f.required('required'),
    }),
  username: yup
    .string('Username must be a string')
    .email('Invalid Email')
    .nullable()
    .required(),
  firstName: yup.string('This field must be a string').nullable().required(),
  lastName: yup.string('This field must be a string').nullable().required(),
  officeNumber: yup.string('This field must be a string').nullable(),
  officeNumberExtension: yup.string('This field must be a string').nullable(),
  homePhone: yup.string('This field must be a string').nullable(),
  mobileNumber: yup.string('This field must be a string').nullable(),
  address1: yup.string('Address must be a string').nullable(),
  city: yup.string('City must be a string').nullable(),
  stateID: yup.string('State must be a string').nullable(),
  zip: yup.string('Zip must be a string').nullable(),
  county: yup.string('This field must be a string').nullable(),
  type: yup.string('This field must be a string').nullable().required(),
  startDate: yup.date().typeError('Invalid Date').nullable(),
  isActive: yup.bool().nullable(),
  facilityIDs: yup
    .array(yup.string())
    .typeError('This field must be an array')
    .nullable(),
  userSettings: yup
    .array(yup.string())
    .typeError('This field must be an array')
    .nullable(),
});

export const facilityContactModalSchema = yup.object().shape({
  isPrimary: yup.bool().nullable(),
  firstName: yup.string('This field must be a string').nullable(),
  lastName: yup.string('This field must be a string').nullable(),
  positionType: yup.string('This field must be a string').nullable(),
  officeNumber: yup.string('This field must be a string').nullable(),
  officeNumberExtension: yup.string('This field must be a string').nullable(),
  mobileNumber: yup.string('This field must be a string').nullable(),
  email: yup.string('Email must be a string').email('Invalid Email').nullable(),
  noteText: yup.string('This field must be a string').nullable(),
  isActive: yup.bool().nullable(),
});

export const reportSchema = yup.object().shape({
  type: yup.string('This field must be a string').nullable(),
  selectedFacility: yup.string('This field must be a string').nullable(),
  allFacilitiesSelected: yup.bool().nullable(),
});

export const companyContactSchema = yup.object().shape({
  firstName: yup.string('This field must be a string').nullable(),
  lastName: yup.string('This field must be a string').nullable(),
  filingStateID: yup.string('This field must be a string').nullable(),
  filingCounty: yup.string('This field must be a string').nullable(),
  officeNumber: yup.string('This field must be a string').nullable(),
  officeNumberExtension: yup.string('This field must be a string').nullable(),
  fax: yup.string('This field must be a string').nullable(),
  email: yup.string('Email must be a string').email('Invalid Email').nullable(),
  roleType: yup.string('This field must be a string').nullable(),
  type: yup.string('This field must be a string').required(),
  insuranceCompanyID: yup.string('This field must be a string').nullable(),
  mailingAddress: yup.object().shape({
    address1: yup.string('Address must be a string').nullable(),
    city: yup.string('City must be a string').nullable(),
    stateID: yup.string('State must be a string').nullable(),
    zip: yup.string('Zip must be a string').nullable(),
    county: yup.string('This field must be a string').nullable(),
  }),
  noteText: yup.string('This field must be a string').nullable(),
});

export const transactionSchema = yup.object().shape({
  category: yup
    .string('This field must be a string')
    .required('Category is required'),
  action: yup
    .string('This field must be a string')
    .required('Deposit/Withdrawal is required'),
  bankAccountID: yup
    .string('This field must be a string')
    .required('Bank Account is required'),
  date: yup.date().typeError('Invalid Date').required('Date is required'),
  amount: yup
    .string('This field must be a string')
    .required('Amount is required'),
  type: yup.string('This field must be a string').required('Type is required'),
  transactionTypeID: yup.string('This field must be a string').nullable(),
  transferTo: yup.string('This field must be a string').nullable(),
  status: yup
    .string('This field must be a string')
    .required('Status is required'),
  statusReason: yup.string('This field must be a string').nullable(),
  noteText: yup.string('This field must be a string').nullable(),
});

export const investmentsSchema = yup.object().shape({
  type: yup.string('This field must be a string').nullable(),
  enterType: yup.string('This field must be a string').nullable(),
  companyName: yup.string('This field must be a string').nullable(),
  accNum: yup.string('This field must be a string').nullable(),
  currentValue: yup.string('This field must be a string').nullable(),
  status: yup.string('This field must be a string').nullable(),
  closedDate: yup.date().typeError('Invalid Date').nullable(),
  closedValue: yup.string('This field must be a string').nullable(),
  isJoint: yup.bool().nullable().typeError('Must be a Boolean value'),
  namesOnAccount: yup.object().shape({
    firstName: yup.string('This field must be a string').nullable(),
    lastName: yup.string('This field must be a string').nullable(),
  }),
});

export const propertySchema = yup.object().shape({
  isJoint: yup.bool().nullable().typeError('Must be a Boolean value'),
  type: yup.string('This field must be a string').nullable(),
  enterType: yup.string('This field must be a string').nullable(),
  firstName: yup.string('This field must be a string').nullable(),
  lastName: yup.string('This field must be a string').nullable(),
  address1: yup.string('Address must be a string').nullable(),
  city: yup.string('City must be a string').nullable(),
  stateID: yup.string('State must be a string').nullable(),
  zip: yup.string('Zip must be a string').nullable(),
  county: yup.string('County must be a string').nullable(),
  financialStatus: yup.string('County must be a string').nullable(),
  estimatedValue: yup.string('County must be a string').nullable(),
  status: yup.string('County must be a string').nullable(),
  soldDate: yup.date().typeError('Invalid Date').nullable(),
});

export const lifeInsuranceSchema = yup.object().shape({
  companyName: yup.string('This field must be a string').nullable(),
  policyNumber: yup.string('This field must be a string').nullable(),
  ownerFirstName: yup.string('This field must be a string').nullable(),
  ownerLastName: yup.string('This field must be a string').nullable(),
  insuredFirstName: yup.string('This field must be a string').nullable(),
  insuredLastName: yup.string('This field must be a string').nullable(),
  policyType: yup.string('This field must be a string').nullable(),
  faceValue: yup.string('This field must be a string').nullable(),
  cashValue: yup.string('This field must be a string').nullable(),
  asOfDate: yup.date().typeError('Invalid Date').nullable(),
  status: yup.string('This field must be a string').nullable(),
  cancelledDate: yup.date().typeError('Invalid Date').nullable(),
  liquidatedDate: yup.date().typeError('Invalid Date').nullable(),
  liquidatedAmount: yup.string('This field must be a string').nullable(),
  fundsDeposited: yup.string('This field must be a string').nullable(),
  fundsDepositedAcctNum: yup.string('This field must be a string').nullable(),
  fundsDepositedType: yup.string('This field must be a string').nullable(),
  fundsDepositedInstitution: yup
    .string('This field must be a string')
    .nullable(),
});

export const trustBurialArrangementsSchema = yup.object().shape({
  ownerFirstName: yup.string('This field must be a string').nullable(),
  ownerLastName: yup.string('This field must be a string').nullable(),
  year: yup.string('This field must be a string').nullable(),
  make: yup.string('This field must be a string').nullable(),
  model: yup.string('This field must be a string').nullable(),
  primaryUse: yup.string('This field must be a string').nullable(),
  amountOwed: yup.string('This field must be a string').nullable(),
  cashValue: yup.string('This field must be a string').nullable(),
  value: yup.string('This field must be a string').nullable(),
  date: yup.date().typeError('Invalid Date').nullable(),
  status: yup.string('This field must be a string').nullable(),
  soldDate: yup.date().typeError('Invalid Date').nullable(),
  soldAmount: yup.string('This field must be a string').nullable(),
  fundsDeposited: yup.string('This field must be a string').nullable(),
  fundsDepositedAcctNum: yup.string('This field must be a string').nullable(),
  fundsDepositedType: yup.string('This field must be a string').nullable(),
  fundsDepositedInstitution: yup
    .string('This field must be a string')
    .nullable(),
});

export const vehicleSchema = yup.object().shape({
  type: yup.string('This field must be a string').nullable(),
  isPrimaryBeneficiary: yup
    .bool()
    .nullable()
    .typeError('Must be a Boolean value'),
  beneficiaryFirstName: yup.string('This field must be a string').nullable(),
  beneficiaryLastName: yup.string('This field must be a string').nullable(),
  beneficiaryDob: yup.date().typeError('Invalid Date').nullable(),
  beneficiaryHomeCountry: yup.string('This field must be a string').nullable(),
  isGrantor: yup.bool().nullable().typeError('Must be a Boolean value'),
  grantorFirstName: yup.string('This field must be a string').nullable(),
  grantorLastName: yup.string('This field must be a string').nullable(),
  grantorDob: yup.date().typeError('Invalid Date').nullable(),
  grantorHomeCountry: yup.string('This field must be a string').nullable(),
  isInitialTrustee: yup.bool().nullable().typeError('Must be a Boolean value'),
  initialTrusteeFirstName: yup.string('This field must be a string').nullable(),
  initialTrusteeLastName: yup.string('This field must be a string').nullable(),
  initialTrusteeAddress1: yup.string('This field must be a string').nullable(),
  initialTrusteeStateID: yup.string('This field must be a string').nullable(),
  initialTrusteePhone: yup.string('This field must be a string').nullable(),
  initialTrusteeCity: yup.string('This field must be a string').nullable(),
  initialTrusteeZip: yup.string('This field must be a string').nullable(),
  initialTrusteeCounty: yup.string('This field must be a string').nullable(),
  isReplacementTrustee: yup
    .bool()
    .nullable()
    .typeError('Must be a Boolean value'),
  replacementTrusteeFirstName: yup
    .string('This field must be a string')
    .nullable(),
  replacementTrusteeLastName: yup
    .string('This field must be a string')
    .nullable(),
  replacementTrusteeAddress1: yup
    .string('This field must be a string')
    .nullable(),
  replacementTrusteeStateID: yup
    .string('This field must be a string')
    .nullable(),
  replacementTrusteePhone: yup.string('This field must be a string').nullable(),
  replacementTrusteeCity: yup.string('This field must be a string').nullable(),
  replacementTrusteeZip: yup.string('This field must be a string').nullable(),
  replacementTrusteeCounty: yup
    .string('This field must be a string')
    .nullable(),
  incomes: yup.object().shape({
    source: yup.string('This field must be a string').nullable(),
    amount: yup.string('This field must be a string').nullable(),
  }),
  isTrustee: yup.bool().nullable().typeError('Must be a Boolean value'),
  trusteeFirstName: yup.string('This field must be a string').nullable(),
  trusteeLastName: yup.string('This field must be a string').nullable(),
  bankName: yup.string('This field must be a string').nullable(),
  routingNumber: yup.string('This field must be a string').nullable(),
  accountNumber: yup.string('This field must be a string').nullable(),
  accountTitle: yup.string('This field must be a string').nullable(),
  initialDeposit: yup.string('This field must be a string').nullable(),
  initialDepositDate: yup.date().typeError('Invalid Date').nullable(),
  monthlyBankFee: yup.string('This field must be a string').nullable(),
  revocableStatus: yup.string('This field must be a string').nullable(),
  funeralHomeName: yup.string('This field must be a string').nullable(),
  funeralHomeAccountNum: yup.string('This field must be a string').nullable(),
  funeralHomeAddress1: yup.string('This field must be a string').nullable(),
  funeralHomeStateID: yup.string('This field must be a string').nullable(),
  funeralHomeCity: yup.string('This field must be a string').nullable(),
  funeralHomeZip: yup.string('This field must be a string').nullable(),
  funeralHomeCounty: yup.string('This field must be a string').nullable(),
  funeralHomeValue: yup.string('This field must be a string').nullable(),
  hasArrangement: yup.string('This field must be a string').nullable(),
  trustFundedBy: yup.string('This field must be a string').nullable(),
  enterFundedBy: yup.string('This field must be a string').nullable(),
  trustTaxID: yup.string('This field must be a string').nullable(),
  dateTrustFunded: yup.date().typeError('Invalid Date').nullable(),
});

export const applicationSchema = yup.object().shape({
  assigneeIDs: yup
    .array(yup.string())
    .typeError('This field must be an array')
    .nullable(),
  startedOn: yup.date().typeError('Invalid Date').required('required'),
  medicaidNeededBy: yup.date().typeError('Invalid Date').nullable(),
  anticipatedMedicaidDate: yup.date().typeError('Invalid Date').nullable(),
  applicationType: yup.string('This field must be a string').required(),
  info: yup.string('This field must be a string').nullable(),
  applicationStatus: yup.string('This field must be a string').required(),
  medicaidCaseworkerID: yup.string('This field must be a string').nullable(),
  personID: yup
    .string('This field must be a string')
    .when(['$isPersonRequired'], {
      is: (isPersonRequired) => isPersonRequired,
      then: (s) => s.required(),
    })
    .nullable(),
  renewalDate: yup
    .date()
    .typeError('Invalid Date')
    .when('$applicationStatus', (applicationStatus, schema) => {
      return applicationStatus === applicationStatusTypes.Approved ||
        applicationStatus === applicationStatusTypes.AdjustmentNeeded
        ? schema.required('Required')
        : schema;
    })
    .nullable(),
  medicaidCaseID: yup.string('This field must be a string').nullable(),
  isOutsourced: yup.boolean().required('required'),
  outsourceCompanyContactID: yup.string().nullable(),
  countyID: yup.string('This field must be a string').nullable(),
  ...statusChangeSchema,
});

export const createCompanyIntegrationMappingSchema = yup.object().shape({
  from: yup.string('This field must be a string').required(),
  to: yup.string('This field must be a string').required(),
  type: yup.string('This field must be a string').required(),
});

export const coverageSchema = yup.object().shape({
  type: yup.string('This field must be a string').required(),
  description: yup
    .string('This field must be a string')
    .when(['type'], {
      is: (type) => type === coverageTypes.PrivatePay,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  memberID: yup.string('This field must be a string').nullable(),
  policyStartDate: yup.date().typeError('Invalid Date').nullable(),
  policyEndDate: yup.date().typeError('Invalid Date').nullable(),
  noteText: yup.string('This field must be a string').nullable(),
  isLongTerm: yup.boolean().required('Required'),
  //do we want to validate against current facility id? for now no
  companyPayerID: yup
    .string('This field must be a string')
    .when(['type'], {
      is: (type) => type === coverageTypes.Policy,
      then: (s) => s.required('Required'),
    })
    .nullable(),
});

export const coverageGapSchema = yup.object().shape({
  startDayNumber: yup
    .string('This field must be a string')
    .when(['$showCoverageLevels', '$position'], {
      is: (showCoverageLevels, position) =>
        position === coveragePeriodPositions.Primary && showCoverageLevels,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  amount: yup
    .string('This field must be a string')
    .when(['$showCoverageLevels', '$position'], {
      is: (showCoverageLevels, position) =>
        position === coveragePeriodPositions.Primary && showCoverageLevels,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  amountType: yup
    .string('This field must be a string')
    .when(['$showCoverageLevels', '$position'], {
      is: (showCoverageLevels, position) =>
        position === coveragePeriodPositions.Primary && showCoverageLevels,
      then: (s) => s.required('Required'),
    })
    .nullable(),
});

export const coveragePeriodSchema = yup.object().shape({
  position: yup.string('This field must be a string').required(),
  description: yup
    .string('This field must be a string')
    .when(['isPending'], {
      is: (isPending) => isPending === true,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  startDate: yup
    .date()
    .typeError('Invalid Date')
    .nullable()
    .required('Required'),
  endDate: yup.date().typeError('Invalid Date').nullable(),
  isPending: yup.boolean().required('Required'),
  coverageID: yup
    .string('This field must be a string')
    .when(['isPending'], {
      is: (isPending) => isPending === false,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  coverageAmount: yup
    .string('This field must be a string')
    .when(['$showCoverageAmount', 'position'], {
      is: (showCoverageAmount, position) =>
        position !== coveragePeriodPositions.Primary && showCoverageAmount,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  coverageAmountType: yup
    .string('This field must be a string')
    .when(['coverageAmount', 'postion'], {
      is: (coverageAmount, position) =>
        position !== coveragePeriodPositions.Primary && coverageAmount > 0,
      then: (s) => s.required('Required'),
    })
    .nullable(),
  gaps: yup
    .array(coverageGapSchema)
    .typeError('This field must be an array')
    .when(['$showCoverageLevels', 'position'], {
      is: (showCoverageLevels, position) =>
        position === coveragePeriodPositions.Primary && showCoverageLevels,
      then: (field) => field.min(1, 'At least 1 coverage level is required'),
    })
    .nullable(),
  estimatedEndDate: yup.date().typeError('Invalid Date').nullable(),
});

export const adtSchema = yup.object().shape({
  type: yup.string('This field must be a string').required('Required'),
  currentFacilityID: yup
    .string('This field must be a string')
    .required('Required')
    .nullable(),
  date: yup.date().typeError('Invalid Date').required('Required').nullable(),
  noteText: yup.string('This field must be a string').nullable(),
});
