import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import {
  DateInput,
  TextInput,
  SelectInput,
  CheckboxInput,
  AutocompleteInput,
} from '../common/inputs';
import { DocumentsList } from './DocumentsList';
import { applicationSchema as schema } from './schema';
import {
  applicationDocTypes,
  applicationTypes,
  submissionMethodTypes as submissionMethods,
} from '../../lib';
import { caseSelectors, uiSelectors } from '../../state';

function StatusChangeSubmitted({
  classes,
  errors,
  hasApplicationSubmissionValidation,
  onChange,
  onChangeApplicationData, //onChange for the application fields (non- status change fields)
  onChangeDocuments,
  state,
  applicationState,
  submissionMethodTypes,
  showApplicationFields = false,
  historicalStatusChangeEdit = false,
}) {
  const medicaidCaseworkers = useSelector(uiSelectors.medicaidCaseworkers);
  const counties = useSelector(uiSelectors.counties);

  const [docsNeeded, setDocsNeeded] = useState([]);
  const [prevSubmissionMethodType, setPrevSubmissionMethodType] = useState(
    state.submissionMethodType,
  );

  useEffect(() => {
    const newDocsNeeded = [];
    if (
      hasApplicationSubmissionValidation &&
      state.submissionMethodType &&
      (prevSubmissionMethodType !== state.submissionMethodType ||
        docsNeeded.length === 0)
    ) {
      switch (state.submissionMethodType) {
        case submissionMethods.HandDelivered:
        case submissionMethods.Fax:
          newDocsNeeded.push({
            documentType: applicationDocTypes.Application,
            required: false,
          });
          newDocsNeeded.push({
            documentType: applicationDocTypes.Receipt,
            required: true,
          });
          break;
        case submissionMethods.Mail:
          newDocsNeeded.push({
            documentType: applicationDocTypes.Application,
            required: false,
          });
          newDocsNeeded.push({
            documentType: applicationDocTypes.Receipt,
            required: false,
          });
          break;
        case submissionMethods.Email:
          newDocsNeeded.push({
            documentType: applicationDocTypes.Application,
            required: false,
          });
          newDocsNeeded.push({
            documentType: applicationDocTypes.EmailScreenshot,
            required: true,
          });
          break;
        case submissionMethods.OnlineSubmission:
          newDocsNeeded.push({
            documentType: applicationDocTypes.Application,
            required: false,
          });
          newDocsNeeded.push({
            documentType: applicationDocTypes.ConfirmationScreenshot,
            required: true,
          });
          break;
        default:
          break;
      }
      setDocsNeeded(newDocsNeeded);
      setPrevSubmissionMethodType(state.submissionMethodType);
    }
  }, [
    state.submissionMethodType,
    docsNeeded.length,
    hasApplicationSubmissionValidation,
    prevSubmissionMethodType,
  ]);

  useEffect(() => {
    if (docsNeeded.length > 0) {
      onChangeDocuments(
        docsNeeded.map((doc) => ({
          ...state.documents.find((d) => doc.documentType === d.documentType),
          ...doc,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsNeeded]);

  return (
    <>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Submitted'
          schema={schema}
          error={errors.statusDate}
          required
        />
      </Grid>
      {showApplicationFields && (
        <Fragment>
          <Grid item xs={6} className={classes.gridModalItem}>
            <AutocompleteInput
              fullWidth
              textFieldProps={{
                label: 'County',
                onChange: onChangeApplicationData,
              }}
              autocompleteProps={{
                options: counties,
                value: applicationState.countyID,
                name: 'countyID',
              }}
              schema={schema}
              error={errors.countyID}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridModalItem}>
            <TextInput
              fullWidth
              className={classes.inputRow}
              value={applicationState.medicaidCaseID || ''}
              name='medicaidCaseID'
              onChange={onChangeApplicationData}
              label='Case ID #'
              schema={schema}
              error={errors.medicaidCaseID}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridModalItem}>
            <SelectInput
              fullWidth
              className={classes.inputRow}
              value={applicationState.medicaidCaseworkerID || ''}
              menuItems={medicaidCaseworkers}
              name='medicaidCaseworkerID'
              onChange={onChangeApplicationData}
              label='Medicaid caseworker'
              schema={schema}
              error={errors.medicaidCaseworkerID}
            />
          </Grid>
        </Fragment>
      )}

      {hasApplicationSubmissionValidation ? (
        <>
          <Grid item xs={6} className={classes.gridModalItem}>
            <SelectInput
              fullWidth
              className={classes.inputRow}
              value={state.submissionMethodType || ''}
              name='submissionMethodType'
              onChange={onChange}
              label='Submission method'
              menuItems={submissionMethodTypes}
              schema={schema}
              error={errors.submissionMethodType}
              required
            />
          </Grid>
          {!historicalStatusChangeEdit && <Grid item xs={6} />}

          {state.submissionMethodType && (
            <DocumentsList
              documents={state.documents}
              error={errors.documents}
              onChange={onChangeDocuments}
            />
          )}
        </>
      ) : (
        <Grid item xs={6} className={classes.gridModalItem} />
      )}
    </>
  );
}
function ApprovedBase({
  applicationType,
  classes,
  errors,
  facilityState,
  onChange,
  state,
  applicationState,
  onChangeApplicationData,
  historicalStatusChangeEdit = false,
}) {
  var isApprovedAndNotRenewal = applicationType !== applicationTypes.Renewal;
  return (
    <Fragment>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Approval received'
          schema={schema}
          error={errors.statusDate}
          required
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.coverageBeginDate}
          isDateOnly={false}
          name='coverageBeginDate'
          onChange={onChange}
          label='Coverage begin'
          schema={schema}
          error={errors.coverageBeginDate}
          required={isApprovedAndNotRenewal}
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.medicaidCostShare}
          name='medicaidCostShare'
          onChange={onChange}
          label={
            facilityState === 'NY' ? 'NAMI' : 'Medicaid resident liability'
          }
          schema={schema}
          error={errors.medicaidCostShare}
          format='currency'
          required
        />
      </Grid>
      {!historicalStatusChangeEdit && (
        <Grid item xs={6} className={classes.gridModalItem}>
          <DateInput
            fullWidth
            sx={{ width: '100%' }}
            className={classes.inputRow}
            value={applicationState.renewalDate}
            isDateOnly={false}
            name='renewalDate'
            onChange={onChangeApplicationData}
            label='Renewal date'
            schema={schema}
            error={errors.renewalDate}
            required
          />
        </Grid>
      )}
    </Fragment>
  );
}
function StatusChangeApproved({
  applicationType,
  classes,
  errors,
  facilityState,
  onChange,
  state,
  applicationState,
  onChangeApplicationData,
  historicalStatusChangeEdit = false,
}) {
  return (
    <Fragment>
      <ApprovedBase
        applicationType={applicationType}
        classes={classes}
        errors={errors}
        facilityState={facilityState}
        onChange={onChange}
        state={state}
        applicationState={applicationState}
        onChangeApplicationData={onChangeApplicationData}
        historicalStatusChangeEdit={historicalStatusChangeEdit}
      />
      <Grid item xs={12} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Approval details'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
      <Grid
        item
        xs={state.hasPenalty ? 4 : 12}
        className={classes.gridModalItem}
        style={{ padding: '0 8.5px 0 32px', height: 82, alignSelf: 'center' }}
      >
        <CheckboxInput
          checkboxes={[
            {
              labelProps: {
                label: 'Penalty',
                className: classes.smallLabel,
              },
              checkboxProps: {
                name: 'hasPenalty',
                checked: state.hasPenalty,
                onChange,
              },
            },
          ]}
        />
      </Grid>
      {state.hasPenalty && (
        <>
          <Grid
            item
            xs={4}
            className={classes.gridModalItem}
            style={{ padding: '0px 8.5px 0 32px' }}
          >
            <TextInput
              fullWidth
              className={classes.inputRow}
              value={state.penaltyAmount}
              name='penaltyAmount'
              onChange={onChange}
              label='Amount'
              schema={schema}
              error={errors.penaltyAmount}
              format='currency'
              required
            />
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.gridModalItem}
            style={{ padding: '0px 32px 0 8.5px' }}
          >
            <DateInput
              fullWidth
              sx={{ width: '100%' }}
              className={classes.inputRow}
              value={state.penaltyEndDate}
              isDateOnly={false}
              name='penaltyEndDate'
              onChange={onChange}
              label='End date'
              schema={schema}
              error={errors.penaltyEndDate}
              required
            />
          </Grid>
        </>
      )}

      <Grid
        item
        xs={state.hasMedicalExpense ? 4 : 12}
        className={classes.gridModalItem}
        style={{ padding: '0 8.5px 0 32px', height: 82, alignSelf: 'center' }}
      >
        <CheckboxInput
          checkboxes={[
            {
              labelProps: {
                label: 'Monthly medical expense',
                className: classes.smallLabel,
              },
              checkboxProps: {
                name: 'hasMedicalExpense',
                checked: state.hasMedicalExpense,
                onChange,
              },
            },
          ]}
        />
      </Grid>
      {state.hasMedicalExpense && (
        <>
          <Grid
            item
            xs={4}
            className={classes.gridModalItem}
            style={{ padding: '0px 8.5px 0 32px' }}
          >
            <TextInput
              fullWidth
              className={classes.inputRow}
              value={state.medicalExpenseAmount}
              name='medicalExpenseAmount'
              onChange={onChange}
              label='Amount'
              schema={schema}
              error={errors.medicalExpenseAmount}
              format='currency'
              required
            />
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.gridModalItem}
            style={{ padding: '0px 32px 0 8.5px' }}
          >
            <TextInput
              fullWidth
              className={classes.inputRow}
              value={state.recipient}
              name='recipient'
              onChange={onChange}
              label='Recipient'
              schema={schema}
              error={errors.recipient}
              required
            />
          </Grid>
        </>
      )}
    </Fragment>
  );
}
function StatusChangeAdjustmentNeeded({
  applicationType,
  classes,
  errors,
  facilityState,
  onChange,
  state,
  applicationState,
  onChangeApplicationData,
  historicalStatusChangeEdit = false,
}) {
  return (
    <Fragment>
      <ApprovedBase
        applicationType={applicationType}
        classes={classes}
        errors={errors}
        facilityState={facilityState}
        onChange={onChange}
        state={state}
        applicationState={applicationState}
        onChangeApplicationData={onChangeApplicationData}
        historicalStatusChangeEdit={historicalStatusChangeEdit}
      />

      <Grid item xs={12} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Adjustment details'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
    </Fragment>
  );
}

function StatusChangeDenied({
  state,
  errors,
  onChange,
  classes,
  applicationState,
}) {
  return (
    <Fragment>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Denial date'
          schema={schema}
          error={errors.statusDate}
          required
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          fullWidth
          className={classes.inputRow}
          value={state.appealDueDate}
          isDateOnly={false}
          name='appealDueDate'
          onChange={onChange}
          label='Appeal due date'
          schema={schema}
          error={errors.appealDueDate}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Denial reason'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
    </Fragment>
  );
}

function StatusChangeNotPursuing({
  state,
  errors,
  onChange,
  classes,
  applicationState,
  notPursuingReasons,
}) {
  return (
    <Fragment>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Not pursuing date'
          schema={schema}
          error={errors.statusDate}
          required
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <SelectInput
          fullWidth
          className={classes.inputRow}
          value={state.notPursuingReason || ''}
          name='notPursuingReason'
          onChange={onChange}
          label='Not pursuing reason'
          menuItems={notPursuingReasons}
          schema={schema}
          error={errors.notPursuingReason}
          required
          alphabetize
        />
      </Grid>
      <Grid item xs={12} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Note'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
    </Fragment>
  );
}
function StatusChangePendingApproval({
  state,
  errors,
  onChange,
  classes,
  applicationState,
}) {
  return (
    <Fragment>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Pending approval date'
          schema={schema}
          error={errors.statusDate}
          required
        />
      </Grid>
      <Grid item xs={12} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Status details'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
    </Fragment>
  );
}
function StatusChangeAppealed({
  state,
  errors,
  onChange,
  classes,
  applicationState,
}) {
  const assignees = useSelector(caseSelectors.caseCompanyUsers);
  const judges = useSelector(uiSelectors.judges);
  return (
    <Fragment>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Appeal submitted date'
          schema={schema}
          error={errors.statusDate}
          required
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.hearingDate}
          isDateOnly={false}
          name='hearingDate'
          onChange={onChange}
          label='Appeal hearing date'
          schema={schema}
          error={errors.hearingDate}
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <SelectInput
          fullWidth
          className={classes.inputRow}
          value={state.companyContactID || ''}
          name='companyContactID'
          onChange={onChange}
          label='Judge'
          menuItems={judges}
          schema={schema}
          error={errors.companyContactID}
          clearable
        />
      </Grid>
      <Grid item xs={6} className={classes.gridModalItem}>
        <SelectInput
          SelectProps={{ multiple: true }}
          fullWidth
          className={classes.inputRow}
          value={state.applicationStatusChangeContactIDs || ''}
          name='applicationStatusChangeContactIDs'
          onChange={onChange}
          label='Attendees'
          menuItems={assignees}
          schema={schema}
          error={errors.applicationStatusChangeContactIDs}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Appeal info'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
    </Fragment>
  );
}
function StatusChangePreparation({
  state,
  errors,
  onChange,
  classes,
  applicationState,
}) {
  return (
    <Fragment>
      <Grid item xs={6} className={classes.gridModalItem}>
        <DateInput
          minDate={applicationState.startedOn}
          fullWidth
          sx={{ width: '100%' }}
          className={classes.inputRow}
          value={state.statusDate}
          isDateOnly={false}
          name='statusDate'
          onChange={onChange}
          label='Preparation date'
          schema={schema}
          error={errors.statusDate}
          required
        />
      </Grid>
      <Grid item xs={12} className={classes.gridModalItem}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={state.noteText}
          name='noteText'
          onChange={onChange}
          label='Status details'
          schema={schema}
          error={errors.noteText}
        />
      </Grid>
    </Fragment>
  );
}

export const statusChangeFieldsComponent = {
  Submitted: <StatusChangeSubmitted />,
  PendingApproval: <StatusChangePendingApproval />,
  Approved: <StatusChangeApproved />,
  AdjustmentNeeded: <StatusChangeAdjustmentNeeded />,
  Denied: <StatusChangeDenied />,
  Appealed: <StatusChangeAppealed />,
  NotPursuing: <StatusChangeNotPursuing />,
  Preparation: <StatusChangePreparation />,
};

export const initialStatusChangeFields = {
  submissionMethodType: null,
  coverageBeginDate: null,
  medicaidCostShare: null,
  penaltyAmount: '',
  penaltyEndDate: null,
  medicalExpenseAmount: '',
  recipient: '',
  appealDueDate: null,
  hearingDate: null,
  documents: [],
  noteText: '',
  statusDate: null,
  companyContactID: '',
  applicationStatusChangeContactIDs: [],
};
