import {
  Avatar as MaterialAvatar,
  Badge,
  Typography,
  TypographyProps,
  Tooltip,
  AvatarProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

type Props = {
  name: string;
  color?: string;
  badgeColor?: 'primary' | 'error';
  badgeSize?: number | string;
  badgeBorder?: number | string;
  typographyProps?: TypographyProps;
  tooltip?: boolean;
} & AvatarProps;
/**
 * @param name full name
 * @param badgeColor leave out for no badge
 */
export function Avatar({
  name,
  color,
  badgeColor,
  badgeSize = 14,
  badgeBorder = 2,
  className,
  typographyProps = {},
  tooltip = false,
  ...avatarProps
}: Props) {
  const badgeClasses = makeStyles(() => ({
    badge: {
      height: badgeSize,
      width: badgeSize,
      border: 'solid #FFFFFF',
      borderWidth: badgeBorder,
      backgroundColor: '#0FC076',
      borderRadius: '100%',
    },
    //active
    colorPrimary: {
      backgroundColor: '#0FC076',
    },
    //inactive
    colorError: {
      backgroundColor: '#F91429',
    },
  }))();

  const avatar = (
    <MaterialAvatar
      {...stringAvatar(name, color, className, typographyProps)}
      {...avatarProps}
    />
  );

  const content = badgeColor ? (
    <Badge
      variant='dot'
      color={badgeColor}
      classes={badgeClasses}
      overlap='circular'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {avatar}
    </Badge>
  ) : (
    avatar
  );

  return (
    <Tooltip title={tooltip ? name : ''}>
      <span>{content}</span>
    </Tooltip>
  );
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(
  name: string,
  color?: string,
  className?: string,
  typographyProps?: TypographyProps,
): AvatarProps {
  return {
    className: clsx(
      makeStyles(() => ({
        c: {
          backgroundColor: color || stringToColor(name),
          textTransform: 'uppercase',
        },
      }))().c,
      className,
    ),
    children: (
      <Typography {...typographyProps}>
        {name.split(' ')[0][0] || ''}
        {name.split(' ')[1]?.[0] || ''}
      </Typography>
    ),
  };
}
