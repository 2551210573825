import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { caseActions, caseSelectors, uiSelectors } from '../../../../state';
import FilterOption from '../../ListViews/filters/FilterOption';
import { AssigneeDisplayButton } from '../AssigneeDisplayButton';
import {
  getAdditionalPersonNames,
  getCaseworkerDisplay,
} from '../../../../lib';

export function AssigneeAutocompleteInput({
  caseId, //can leave out if usePotentialCaseAssignees is set to true
  usePotentialCaseAssignees = false,
  assigneeIDs,
  onChange,
}) {
  const dispatch = useDispatch();
  const potentialCompanyUsers = useSelector(uiSelectors.potentialCompanyUsers);
  const caseCompanyUsers = useSelector(caseSelectors.caseCompanyUsers);
  const assignees = usePotentialCaseAssignees
    ? potentialCompanyUsers
    : caseCompanyUsers;

  const selectedAssigneeList = assignees.filter((a) =>
    assigneeIDs.some((i) => i === a.id),
  );

  useEffect(() => {
    if (!usePotentialCaseAssignees) {
      dispatch(caseActions.getCaseCompanyUsers(caseId));
    }
  }, [dispatch, caseId, usePotentialCaseAssignees]);

  return (
    <FilterOption
      menuItems={assignees}
      selectedMenuItems={selectedAssigneeList}
      label={''}
      onChange={(values) =>
        onChange({ target: { value: values, name: 'assigneeIDs' } })
      }
      multiple
      searchPlaceholder={'Search...'}
      groupedOptionsLabel={'All users'}
      renderButton={({ onClick, ref }) => (
        <AssigneeDisplayButton
          displayText={getCaseworkerDisplay(selectedAssigneeList)}
          tooltipText={getAdditionalPersonNames(selectedAssigneeList)}
          actionText={'Add assignee'}
          onClick={onClick}
          otherProps={{ ref }}
        />
      )}
    />
  );
}
