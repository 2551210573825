import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import { parse, stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { SearchInput } from '../../common/inputs';
import {
  FilterListIcon,
  LaunchIcon,
  CloseIcon,
  SearchIcon,
} from '../../../themes';
import { ExportButton } from '../..';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../hooks';
import FilterDropdown from './FilterDropdown';
import { useStyles } from '../overlays.styles';
import { systemPersistSelectors, uiSelectors } from '../../../state';
import { NoteForm } from '../../modals/NoteForm';

export default function NotesOverlayHeader({
  caseId,
  noteFormProps,
  isPreview,
}) {
  // selectors
  const caseNoteTypes = useSelector(systemPersistSelectors.caseNoteTypes);
  const companyUsers = useSelector(uiSelectors.potentialCompanyUsers);
  const isOverlayOpen = useSelector(uiSelectors.isOverlayOpen);
  // hooks (after selectors bc uses isOverlayOpen selector)
  const classes = useStyles({ isOverlayOpen });
  const {
    noteSearch,
    'list-filters': queryListFilters,
    authors,
  } = useGetQueryParams();
  const updatePageQueryParams = useUpdatePageQueryParams();
  //state
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchOpen, setOpenSearch] = useState(false);
  // functions
  const launchFullPageNotes = () => {
    let url = '';
    if (isOverlayOpen) {
      const params = { view: 'notes' };
      const { pathname, search } = window.location;
      let currentParams = parse(search);
      const newParams = { ...currentParams, ...params };
      const query = Object.keys(newParams).length
        ? `?${stringify(newParams, { skipNull: true })}`
        : '';
      url = `${pathname}${query}`;
    } else {
      url = `/cases/${caseId}?view=notes`;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box className={classes.header}>
      {!isPreview && (
        <>
          {searchOpen ? (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                marginBottom: 16,
              }}
            >
              <SearchInput
                variant='standard'
                iconPosition='start'
                label=''
                style={{ flex: '1' }}
                queryParam={'noteSearch'}
                autoFocus
              />

              <div className={classes.overlayClose}>
                <Button
                  onClick={() => {
                    setOpenSearch(false);
                    updatePageQueryParams({ noteSearch: '' });
                  }}
                  variant='text'
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          ) : (
            <div
              className={classes.headerFirstRow}
              style={{ alignItems: 'center' }}
            >
              <div>
                <Typography className={classes.title}>Notes</Typography>
                <Button
                  onClick={() => launchFullPageNotes()}
                  variant='text'
                  style={{ minWidth: 'unset', marginLeft: -4 }}
                >
                  <LaunchIcon />
                </Button>
              </div>
              <div className={classes.headerFirstRowRight}>
                <div className={classes.notesActions}>
                  <Button
                    onClick={() => setOpenSearch(true)}
                    variant='contained'
                    sx={{
                      transition: 'opacity 300ms linear',
                      '&:hover': { opacity: 0.5, backgroundColor: '#E5E7F5' },
                    }}
                  >
                    <SearchIcon />
                  </Button>
                  <div style={{ position: 'relative' }}>
                    {(queryListFilters?.length > 0 || authors?.length > 0) && (
                      <span className={classes.filterAmount}>
                        {queryListFilters?.length > 0
                          ? authors?.length > 0
                            ? '2'
                            : '1'
                          : authors?.length > 0
                          ? '1'
                          : ''}
                      </span>
                    )}
                    <Button
                      onClick={() => setFiltersOpen(!filtersOpen)}
                      variant='contained'
                      sx={{
                        transition: 'opacity 300ms linear',
                        '&:hover': { opacity: 0.5, backgroundColor: '#E5E7F5' },
                      }}
                    >
                      <FilterListIcon style={{ cursor: 'pointer' }} />
                    </Button>
                  </div>
                  <ExportButton
                    fileName={`${caseId}_notes_${format(new Date(), 'Pp')}`}
                    headersArray={[
                      { key: 'editedByFullName', label: 'Name' },
                      { key: 'typeFriendlyName', label: 'Category' },
                      { key: 'editedOn', label: 'Written Date' },
                      { key: 'text', label: 'Note' },
                    ]}
                    path={`/cases/${caseId}/notes`}
                    queryParams={{
                      isExport: true,
                      Text: noteSearch,
                      noteTypes: queryListFilters,
                      authors,
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {filtersOpen && (
            <div style={{ display: 'flex', marginTop: 16, marginBottom: 16 }}>
              <div className={classes.headerFourthRow}>
                <FilterDropdown
                  filterType={'Category'}
                  filters={caseNoteTypes}
                  queryParam='list-filters'
                  style={{ cursor: 'pointer', marginRight: 16 }}
                  alphabetize
                />
                <FilterDropdown
                  filterType={'Author'}
                  filters={companyUsers}
                  queryParam='authors'
                  style={{ cursor: 'pointer' }}
                />
                <Button
                  className={classes.clearFilters}
                  onClick={() => {
                    updatePageQueryParams({
                      'list-filters': [],
                      authors: [],
                    });
                  }}
                >
                  Clear filters
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      <NoteForm inline {...noteFormProps} autofocus={!isPreview} />
    </Box>
  );
}
