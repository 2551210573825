import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TextField,
  IconButton,
  Popover,
  Paper,
  Divider,
  Button,
  Box,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { SearchIcon, ClearIcon, DoneIcon } from '../../themes';
import { useStyles } from './globalSearch.styles';
import { ActionsTabs } from '../common';
import {
  ListCategories,
  ListCategoryTypes,
} from '../../pages/main/ListViews/helpers/ListHelpers';
import { SharedFilters } from '../../pages/main/ListViews/helpers/SharedListConfig';
import { useDispatch } from 'react-redux';
import { accountActions, residentActions } from '../../state';
import { GlobalSearchOption } from './GlobalSearchOption';
import {
  getResidentTextForCompanyType,
  capitalize,
  RecentActivityViewFilter,
} from '../../lib';
import { useGetUsersData } from '../../hooks';

// #region configs for global search
export const GlobalSearchTypes = {
  Residents: 'ResidentsGlobalSearch',
  Applications: 'ApplicationsGlobalSearch',
};

const filterOptions = [
  SharedFilters.assignedToMe,
  { ...SharedFilters.showArchived, label: 'Include archived' },
  { ...SharedFilters.isResidentActive, label: 'Exclude inactive' },
];
// #endregion

function QuickFilterButton({ name, value, selected, onClick, classes }) {
  return (
    <Button
      className={classes.quickFilterButton}
      style={{ borderColor: selected ? '#096EF8' : '#E5E7F5' }}
      variant={'outlined'}
      onClick={() => onClick(value)}
      startIcon={selected ? <DoneIcon /> : undefined}
      endIcon={selected ? <ClearIcon /> : undefined}
    >
      {name}
    </Button>
  );
}

export function GlobalSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const inputFieldRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [tabPosition, setTabPosition] = useState(GlobalSearchTypes.Residents);
  const [options, setOptions] = useState([]);
  const [recents, setRecents] = useState([]);
  const { companyType } = useGetUsersData();

  const tabs = useMemo(
    () => [
      {
        name: capitalize(`${getResidentTextForCompanyType(companyType)}s`),
        category: ListCategoryTypes.Resident,
        value: GlobalSearchTypes.Residents,
      },
      {
        name: 'Applications',
        category: ListCategoryTypes.Application,
        value: GlobalSearchTypes.Applications,
      },
    ],
    [companyType],
  );

  const anchorEl = inputRef.current;
  const apiUrl = useMemo(
    () =>
      ListCategories[tabs.find((t) => t.value === tabPosition).category]
        .dataURL,
    [tabPosition, tabs],
  );

  const debounceSearch = useMemo(
    () =>
      debounce((value) => {
        setSearchText(value);
      }, 300),
    [],
  );

  const isFilterSelected = useCallback(
    (f) => {
      return Object.keys(filters).includes(f);
    },
    [filters],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleClick = useCallback(() => {
    inputFieldRef.current.blur();
    setOpen(true);
  }, []);

  const onTabClick = useCallback((event, position) => {
    setTabPosition(position);
  }, []);

  const clearSearch = useCallback(() => {
    setInputValue('');
  }, []);

  const onClickQuickFilter = useCallback(
    (filter) => {
      if (isFilterSelected(filter)) {
        //turn it off
        setFilters((prev) => {
          const { [filter]: temp, ...rest } = prev;
          return rest;
        });
      } else {
        //add it
        setFilters((prev) => ({ ...prev, [filter]: true }));
      }
    },
    [isFilterSelected],
  );

  useEffect(() => {
    async function fetchRecents() {
      let filter;
      switch (tabPosition) {
        case GlobalSearchTypes.Residents:
          filter = RecentActivityViewFilter.Residents;
          break;
        case GlobalSearchTypes.Applications:
          filter = RecentActivityViewFilter.Applications;
          break;
        default:
          filter = RecentActivityViewFilter.All;
      }
      const { data } = await dispatch(accountActions.getRecentActivity(filter));
      if (data) {
        const list =
          tabPosition === GlobalSearchTypes.Residents
            ? data.residents
            : data.applications;
        setRecents(list.map((d) => d.data));
      }
    }
    if (open) {
      fetchRecents();
    }
  }, [tabPosition, open, dispatch]);

  //useEffect on change of inputValue -> debounce to set the search text
  useEffect(() => {
    debounceSearch(inputValue);
  }, [inputValue, debounceSearch]);

  //get the options
  useEffect(() => {
    async function fetchOptions() {
      if (searchText.length < 2) {
        setOptions([]); //clear
        return;
      }
      const { data } = await dispatch(
        residentActions.getGlobalSearchResults(
          {
            ...filters,
            text: searchText,
            listType: tabPosition,
          },
          apiUrl,
        ),
      );
      if (data) {
        setOptions(data.results);
      } else {
        setOptions([]); //clear
      }
    }
    fetchOptions();
  }, [searchText, filters, tabPosition, apiUrl, dispatch]);

  return (
    <Fragment>
      <div
        className={classes.globalSearchWrapper}
        ref={inputRef}
        id={'globalSearch'}
      >
        <TextField
          inputRef={inputFieldRef}
          fullWidth
          variant='filled'
          label={`Search ${getResidentTextForCompanyType(companyType)}s`}
          value={inputValue}
          onClick={handleClick}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.inputRoot,
              focused: classes.focused,
              disabled: classes.disabled,
              error: classes.error,
              input: classes.input,
            },
            endAdornment: (
              <>
                <IconButton
                  style={{ visibility: inputValue ? 'visible' : 'hidden' }}
                  onClick={(event) => {
                    setInputValue('');
                    event.stopPropagation();
                  }}
                  size='small'
                >
                  <ClearIcon size='small' className={classes.label} />
                </IconButton>
                <SearchIcon className={classes.label} />
              </>
            ),
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focused,
              error: classes.error,
              disabled: classes.disabled,
            },
          }}
        />
      </div>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: -8 }}
        classes={{ paper: classes.popoverPaper }}
      >
        <Paper className={classes.searchPaper}>
          <TextField
            autoFocus
            fullWidth
            variant='outlined'
            label={`Search ${getResidentTextForCompanyType(companyType)}s`}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            InputProps={{
              classes: {
                root: classes.outlinedRoot,
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
                input: classes.input,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <>
                  <IconButton
                    style={{ visibility: inputValue ? 'visible' : 'hidden' }}
                    onClick={clearSearch}
                    size='small'
                  >
                    <ClearIcon size='small' className={classes.label} />
                  </IconButton>
                  <SearchIcon className={classes.label} />
                </>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focused,
                error: classes.error,
                disabled: classes.disabled,
              },
            }}
          />

          <ActionsTabs
            onClick={onTabClick}
            tabs={tabs}
            tabPosition={tabPosition}
          />
          <Divider className={classes.divider} />
          <div>
            {filterOptions.map((f, i) => (
              <QuickFilterButton
                key={i}
                value={f.name}
                name={f.label}
                classes={classes}
                selected={isFilterSelected(f.name)}
                onClick={onClickQuickFilter}
              />
            ))}
          </div>
          {searchText.length < 2 && recents.length > 0 && (
            <Box sx={{ fontWeight: 700, color: 'text.primary', fontSize: 18 }}>
              Recent
            </Box>
          )}
          <Divider className={classes.divider} />
          <div className={classes.scrollableOptions}>
            {((searchText.length < 2 ? recents : options) || []).map(
              (i, index) => (
                <GlobalSearchOption
                  key={index}
                  option={i}
                  classes={classes}
                  tabPosition={tabPosition}
                  handleClose={handleClose}
                  clearSearch={clearSearch}
                />
              ),
            )}
          </div>
        </Paper>
      </Popover>
    </Fragment>
  );
}
