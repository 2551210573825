import { useMemo } from 'react';
import { Box, Tooltip, TypographyProps, styled } from '@mui/material';
import { NameWithStatusAndHover, PrintTypography } from '../../../components';
import { DateListItem, DateTypeGroup } from '../../../state';
import {
  CaseNavigationSource,
  ResidentCaseViews,
  ResidentDateType,
  pluralize,
} from '../../../lib';
import { useGetQueryParams } from '../../../hooks';

export const CalendarItem = styled(Box)(({ theme }) => ({
  width: 'auto',
  borderRadius: '6px',
  padding: '3px 4px',
  backgroundColor: theme.palette.action.hover,
  alignItems: 'center',

  '@media print': {
    padding: 0,
  },
}));

type LongTextComponentProps = {
  text: string;
  maxLength?: number;
  customWrap?: boolean; // if false then use the default noWrap behavior
  typographyProps?: TypographyProps;
};
export const LongTextComponent = ({
  text,
  maxLength = 50,
  customWrap = false,
  typographyProps = {},
}: LongTextComponentProps) => {
  // note this might be true even if the text doesn't end up truncating (if on a large screen)
  // the workarounds for this are complex so leaving this case as-is
  const isLongText = (text?.length || 0) > maxLength;

  const textDisplay =
    customWrap && isLongText ? `${text.substring(0, maxLength)}...` : text;

  return (
    <Tooltip title={isLongText && text}>
      <PrintTypography noWrap={!customWrap} {...typographyProps}>
        {textDisplay}
      </PrintTypography>
    </Tooltip>
  );
};

export const dateQueryParamFormat = 'yyyy-MM-dd';

export const getDateItemDescription = (event: DateListItem) => {
  switch (event.dateType) {
    case ResidentDateType.ApplicationPostSubmitMilestoneDate:
      return event.applicationDateTypeDisplay;

    case ResidentDateType.DocumentDueDate:
    case ResidentDateType.TodoDueDate:
      return event.metaData.description;

    default:
      return null;
  }
};

export const getDateItemDisplay = (event: DateListItem) => {
  const descriptionText = getDateItemDescription(event);
  const descriptionSuffix = descriptionText ? `: ${descriptionText}` : '';
  return `${event.dateTypeDisplay}${descriptionSuffix}`;
};

export const getDateSummaryDisplay = (eventGroup: DateTypeGroup) => {
  switch (eventGroup.dateType) {
    case ResidentDateType.ApplicationAppealDueDate:
    case ResidentDateType.ApplicationAppealHearingDate:
      return pluralize(eventGroup.dateTypeDisplay, eventGroup.count);

    case ResidentDateType.ApplicationPostSubmitMilestoneDate:
      return `${eventGroup.dateTypeDisplay} ${pluralize(
        'date',
        eventGroup.count,
      )}`;

    case ResidentDateType.DocumentDueDate:
      return `${pluralize('Document', eventGroup.count)} due`;

    case ResidentDateType.TodoDueDate:
      return `${pluralize('Task', eventGroup.count)} due`;

    default:
      return eventGroup.dateTypeDisplay;
  }
};

const getCaseView = (event: DateListItem) => {
  switch (event.dateType) {
    case ResidentDateType.ClinicalEvaluationExpirationDate:
      return ResidentCaseViews.Clinical;
    case ResidentDateType.TodoDueDate:
      return ResidentCaseViews.Todos;
    default:
      return ResidentCaseViews.Application;
  }
};

export const CalendarNameWithStatusAndHover = ({
  event,
  residentNameSize,
}: {
  event: DateListItem;
  residentNameSize?: TypographyProps['variant'];
}) => {
  return (
    <NameWithStatusAndHover
      residentName={`${event.residentFirstName} ${event.residentLastName}`}
      isResidentActive={event.isResidentActive}
      facilityName={event.facilityName}
      caseId={event.caseID}
      caseView={getCaseView(event)}
      sourcePage={CaseNavigationSource.dashboard}
      residentNameSize={residentNameSize}
    />
  );
};

const emptyArray: any[] = [];
export const useCalendarQueryParams = () => {
  const { assignedToMe = 'false', facilities = emptyArray } =
    useGetQueryParams();
  const assignedToMeBool = assignedToMe === 'true';
  const selectedFacilityIds = useMemo(
    () => (Array.isArray(facilities) ? facilities : [facilities]),
    [facilities],
  );
  return { assignedToMe: assignedToMeBool, selectedFacilityIds };
};
