import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../state';
import NewDashboardPage from './NewDashboardPage';
import OldDashboardPage from './OldDashboardPage';

function DashboardPage() {
  const isNewDashboardOn = useSelector(uiSelectors.isNewDashboardOn);
  return isNewDashboardOn ? <NewDashboardPage /> : <OldDashboardPage />;
}

export default memo(DashboardPage);
