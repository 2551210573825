import React, { useCallback, memo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, Divider, Typography, Box } from '@mui/material';
import { authActions } from '../../state';
import {
  LocationCityIconIcon,
  DashboardIcon,
  LogoutIcon,
  SettingsIcon,
  SupervisedUserCircleIcon,
  ContactsIcon,
  SyncAltIcon,
  PersonIcon,
  PrivacyShieldIcon,
} from '../../themes';
import { useGetUsersData } from '../../hooks';
import { useStyles } from './appBarMenu.styles';
import { Copyright, PermissionClaims, Routes, userTypes } from '../../lib';
import SuperAdminMenu from '../../pages/superAdmin/SuperAdminMenu';
import { getYear } from 'date-fns';
import { FlexColumn } from './FlexDiv';

export const UserMenu = memo(({ anchorEl, setAnchorEl }) => {
  return (
    <AppBarMenu
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      MenuListComponent={UserMenuItems}
    />
  );
});

export const SettingsMenu = memo(({ anchorEl, setAnchorEl }) => {
  return (
    <AppBarMenu
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      MenuListComponent={SettingsMenuItems}
    />
  );
});

const AppBarMenu = memo(({ anchorEl, setAnchorEl, MenuListComponent }) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <Menu
      id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.menu }}
    >
      <MenuListComponent handleClose={handleClose} />
    </Menu>
  );
});

const SettingsMenuItems = memo(({ handleClose }) => {
  const classes = useStyles();
  const { PermissionClaim = [], UserTypeClaim } = useGetUsersData();

  return (
    <Fragment>
      {(PermissionClaim.includes(PermissionClaims.SuperAdminEditClaim) ||
        PermissionClaim.includes(PermissionClaims.CompanyEditClaim)) && (
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={Routes.adminSettings.path}
          className={classes.menuItem}
        >
          <SettingsIcon />
          Settings
        </MenuItem>
      )}
      {(PermissionClaim.includes(PermissionClaims.SuperAdminEditClaim) ||
        PermissionClaim.includes(PermissionClaims.CompanyEditClaim)) && (
        <MenuItem
          component={Link}
          to={Routes.adminUsersPage.path}
          onClick={handleClose}
          className={classes.menuItem}
        >
          <SupervisedUserCircleIcon />
          Users
        </MenuItem>
      )}

      <MenuItem
        component={Link}
        to={Routes.facilities.path}
        onClick={handleClose}
        className={classes.menuItem}
      >
        <LocationCityIconIcon />
        Facilities
      </MenuItem>

      <MenuItem
        component={Link}
        to={Routes.caseWorkers.path}
        onClick={handleClose}
        className={classes.menuItem}
      >
        <ContactsIcon />
        Contacts
      </MenuItem>

      {(PermissionClaim.includes(PermissionClaims.SuperAdminEditClaim) ||
        PermissionClaim.includes(PermissionClaims.CompanyEditClaim)) && (
        <MenuItem
          component={Link}
          to={Routes.mappingPage.path}
          onClick={handleClose}
          className={classes.menuItem}
        >
          <SyncAltIcon />
          Mapping
        </MenuItem>
      )}

      {PermissionClaim.includes(PermissionClaims.MultiCompanyUserClaim) && (
        <MenuItem
          component={Link}
          to={Routes.facilitiesDashboard.path}
          onClick={handleClose}
          className={classes.menuItem}
        >
          <DashboardIcon />
          {UserTypeClaim === userTypes.Tracker ? 'My' : 'All'} Facilities
        </MenuItem>
      )}

      {PermissionClaim.includes(PermissionClaims.SuperAdminEditClaim) && (
        <SuperAdminMenu handleClose={handleClose} classes={classes} />
      )}

      <Divider />

      <FlexColumn textAlign='center' paddingLeft={1} paddingRight={1}>
        <Typography variant='caption' component='div'>
          {Copyright} {getYear(new Date())} Aidace. All rights reserved.
        </Typography>
        <Box>
          <Typography variant='caption' color='text.disabled'>
            Support:{' '}
          </Typography>
          <Typography
            variant='caption'
            color='text.disabled'
            component='a'
            href='mailto:support@aidace.com'
            target='_blank'
          >
            support@aidace.com
          </Typography>
        </Box>
      </FlexColumn>
    </Fragment>
  );
});
const UserMenuItems = memo(({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    PermissionClaim = [],
    UserTypeClaim,
    firstName,
    lastName,
    userName,
  } = useGetUsersData();
  const handleLogout = useCallback(() => {
    handleClose();
    dispatch(authActions.logout());
  }, [dispatch, handleClose]);

  const isSuperAdmin = PermissionClaim.includes(
    PermissionClaims.SuperAdminEditClaim,
  );
  return (
    <Fragment>
      <div className={classes.userDetails}>
        <div className={classes.userType}>
          {isSuperAdmin ? 'Super Admin' : UserTypeClaim}
        </div>
        <div className={classes.userName}>
          {firstName} {lastName}
        </div>
        <div className={classes.email}>{userName}</div>
      </div>

      <Divider />

      <MenuItem
        component={Link}
        to={Routes.userProfile.path}
        onClick={handleClose}
        className={classes.menuItem}
      >
        <PersonIcon className={classes.mdIcon} />
        Profile
      </MenuItem>

      <MenuItem
        component='a'
        href='https://www.aidace.com/privacy-policy/'
        target='_blank'
        onClick={handleClose}
      >
        <PrivacyShieldIcon className={classes.mdIcon} />
        Privacy Policy
      </MenuItem>

      <MenuItem onClick={handleLogout} className={classes.menuItem}>
        <LogoutIcon className={classes.mdIcon} />
        Logout
      </MenuItem>
    </Fragment>
  );
});
