import React, { useCallback, useState, useEffect, useRef } from 'react';
import { bool, date, object, string } from 'yup';
import { Editor } from '@tinymce/tinymce-react';
import clsx from 'clsx';
import { Button, Box, Grid, FormHelperText, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from './FormModal';
import {
  TextInput,
  CheckboxInput,
  DateInput,
  AutocompleteAsync,
  AutocompleteInput,
  SelectInput,
} from '../common/inputs';
import { useNotify, useGetUsersData } from '../../hooks';
import { resourceActions, caseActions, caseSelectors } from '../../state';
import { Loader } from '../../components';
import {
  validateOnSubmit,
  formatDate,
  userTypes,
  formatUsersWithAvatars,
  getResidentTextForCompanyType,
  capitalize,
  EditorSecureImageUploadHandler,
} from '../../lib';
import { sharedFormStyles } from './sharedStyles';
import { Cancel } from '../../themes';
import { NameWithAvatar } from '../name';

const useStyles = makeStyles((theme) => ({
  ...sharedFormStyles(theme),
}));

export function TaskForm({
  disableFromMarkingOtherTasks,
  disableFromEditingOtherTasks,
  open,
  handleClose,
  id,
  caseId,
  refresh,
  quickActionCaseName,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const editorRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [personName, setPersonName] = useState('');
  const [casePeople, setCasePeople] = useState([]);
  const [disableEditing, setDisableEditing] = useState(false);
  const [initialHtmlContent, setInitialHtmlContent] = useState('');
  const [state, setState] = useState({
    title: '',
    taskDetailsText: '',
    taskDetailsHtmlContent: '',
    casePersonID: '',
    dateDue: null,
    taskAssignedTo: '',
    caseID: '',
    doneOn: null,
    createdOn: null,
    updatedOn: null,
    createdBy: '',
    createdByFirstName: '',
    createdByLastName: '',
    isPrivate: false,
  });

  const assignees = useSelector(caseSelectors.caseCompanyUsers);
  const initialRender = useRef(true);
  const { userId, companyID, CompanyUserIdClaim, UserTypeClaim, companyType } =
    useGetUsersData();

  const assigneeList = formatUsersWithAvatars({
    users: assignees,
    CompanyUserIdClaim,
    companyID,
    classes,
  });

  useEffect(() => {
    if (!initialRender.current) {
      return;
    }
    (async function () {
      if (id) {
        const response = await dispatch(
          resourceActions.fetchResourceById({ baseUrl: '/alerts/task', id }),
        );
        const { data, error } = response;
        if (error) {
          return notify(error?.message || 'An error occurred', 'error');
        }
        const {
          id: _id,
          caseID,
          personFirstName,
          personLastName,
          applicationID,
          createdBy,
          taskAssignedTo,
          taskDetailsText,
          taskDetailsHtmlContent,
          ...rest
        } = data;
        setState((s) => ({
          ...s,
          ...rest,
          caseID: caseID,
          createdBy,
          taskAssignedTo,
          taskDetailsText,
          taskDetailsHtmlContent,
        }));
        setInitialHtmlContent(taskDetailsHtmlContent || taskDetailsText);
        setPersonName(`${personFirstName || ''} ${personLastName || ''}`);
        setHideDeleteBtn(userId !== createdBy);
        setDisableEditing(
          disableFromEditingOtherTasks &&
            CompanyUserIdClaim !== taskAssignedTo.toString() &&
            userId !== createdBy,
        );
      } else if (caseId) {
        const casePeopleData = await dispatch(
          resourceActions.fetchResourceById({
            baseUrl: '/cases',
            id: caseId,
            path: 'people',
          }),
        );
        const casePeopleModified = casePeopleData.data.map((cp) => {
          return { ...cp, id: cp.casePersonID };
        });
        setCasePeople(casePeopleModified);
        setState((s) => ({ ...s, caseID: parseInt(caseId) }));
      }
      initialRender.current = false;
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [companyID]);

  useEffect(() => {
    const id = caseId ?? state.caseID;
    dispatch(caseActions.getCaseCompanyUsers(id));
  }, [dispatch, caseId, state.caseID]);

  useEffect(() => {
    if (caseId && casePeople.length === 1) {
      setState((s) => ({ ...s, casePersonID: casePeople[0].casePersonID }));
      setPersonName(quickActionCaseName);
    } else if (caseId && casePeople.length > 1) {
      let residents = casePeople.filter((person) => person.isResident === true);
      if (residents.length === 1) {
        setState((s) => ({ ...s, casePersonID: residents[0].casePersonID }));
      }
    }
    // eslint-disable-next-line
  }, [casePeople]);

  const onSave = useCallback(
    (markAsDone) => {
      return validateOnSubmit({ values: state, schema, setErrors }).then(
        async () => {
          setSubmitting(true);

          //we only upload the images immediately before saving to avoid uploading for no reason
          //(in the case where they add an image and then delete it etc.)

          //only get the content once the images are finished uploading to avoid saving the images in base64
          const didSucceed = await editorRef.current
            .uploadImages()
            .then(() => {
              return true;
            })
            .catch((err) => {
              return false;
            });
          if (!didSucceed) {
            setSubmitting(false);
            notify('An error occurred while uploading images', 'error');
            return;
          }

          const taskDetailsHtmlContent = editorRef.current.getContent();
          const taskDetailsText = editorRef.current.getContent({
            format: 'text',
          });

          const response = await dispatch(
            resourceActions.postResource({
              baseUrl: '/alerts/task',
              payload: {
                ...state,
                id,
                taskDetailsHtmlContent,
                taskDetailsText,
                ...(markAsDone ? { doneOn: new Date() } : {}),
              },
            }),
          );
          setSubmitting(false);
          const { data } = response;
          if (data) {
            refresh();
            handleClose();
          }
        },
      );
      // .catch((e) => console.error('error ', e));
    },
    [dispatch, handleClose, id, refresh, state, notify],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl: '/alerts/task',
        id: id,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (error) {
      return notify(error?.message || 'An error occurred', 'error');
    }
    notify('Changes saved');
    refresh();
    handleClose();
  }, [dispatch, handleClose, id, notify, refresh]);

  const onChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState((curState) => ({ ...curState, [name]: value }));
  }, []);

  const handleResidentChange = useCallback((e) => {
    const { value, selectedItemName, secondaryValue } = e.target;
    setState((curState) => ({
      ...curState,
      casePersonID: value,
      caseID: secondaryValue || curState.caseID,
    }));
    setPersonName(selectedItemName);
  }, []);

  const onEditorImageUpload = useCallback(async (blobInfo, progress) => {
    return await EditorSecureImageUploadHandler(blobInfo, progress, 'Notes');
  }, []);

  //clear assignee if the state.caseId changes (access may be different),
  useEffect(() => {
    //case id can only be changed when creating a task, dont clear for existing taskId
    if (!id) {
      setState((curState) => ({ ...curState, taskAssignedTo: '' }));
    }
  }, [id, state.caseID]);

  return (
    <FormModal
      handleClose={handleClose}
      open={open}
      title={id ? 'View Task' : 'Create Task'}
      onSave={() => onSave(true)}
      saveBtnText='Save and mark as done'
      secondarySaveBtn={
        <Button
          color='primary'
          variant='contained'
          onClick={() => onSave(false)}
          sx={{
            ml: '20px',
          }}
          disabled={submitting}
        >
          Save
        </Button>
      }
      isEdit={!!id}
      handleDelete={handleDelete}
      hideDeleteBtn={hideDeleteBtn}
      submitting={submitting}
      hideFooter={loading || state.doneOn || disableEditing}
      rightSideHeader={
        id &&
        (state.doneOn || disableEditing) && (
          <IconButton onClick={handleClose} size='large'>
            <Cancel />
          </IconButton>
        )
      }
      disableEnforceFocus={true} //need this in order for tinyMce to work fully within the modal
    >
      <div>
        {UserTypeClaim === userTypes.Admin && (
          <div
            className={clsx(classes.formPadding)}
            style={{ marginTop: -55, marginBottom: 15, textAlign: 'right' }}
          >
            <CheckboxInput
              checkboxes={[
                {
                  labelProps: {
                    label: 'Private task',
                  },
                  checkboxProps: {
                    name: 'isPrivate',
                    checked: state.isPrivate,
                    onChange,
                    error: errors?.isPrivate,
                  },
                },
              ]}
              schema={schema}
            />
          </div>
        )}
        {loading ? (
          <div className={classes.cwRequestLoader}>
            <Loader size={48} />
          </div>
        ) : (
          <div>
            {(id || caseId) && personName && personName.trim() !== '' && (
              <NameWithAvatar personName={personName} />
            )}
            <div
              style={{
                marginLeft: 35,
                color: '#003FED',
                fontSize: 13,
              }}
            >
              {state.createdOn && (
                <span>
                  Created on {formatDate(state.createdOn, 'MMMM dd', true)}
                </span>
              )}
              {state.updatedOn && (
                <span>
                  <span
                    style={{ fontWeight: 700, marginRight: 8, marginLeft: 8 }}
                  >
                    |
                  </span>
                  Last update on {formatDate(state.updatedOn, 'MMMM dd', true)}
                </span>
              )}
            </div>
            <Box p={3}>
              <Grid container rowSpacing={4} columnSpacing={5}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    multiline
                    rows={1}
                    value={state.title}
                    name='title'
                    onChange={onChange}
                    label='Title'
                    schema={schema}
                    error={errors.title}
                    disabled={state.doneOn || disableEditing}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    disabled={state.doneOn || disableEditing}
                    tinymceScriptSrc={process.env.REACT_APP_TINY_MCE_CDN}
                    apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                    initialValue={initialHtmlContent}
                    init={{
                      promotion: false,
                      statusbar: false,
                      plugins:
                        ' searchreplace autolink visualblocks  ' +
                        ' link table advlist lists autoresize',
                      autoresize_bottom_margin: 0,
                      placeholder: 'Task details',
                      min_height: 300,
                      max_height: 450,
                      menubar: 'edit  insert format table',
                      toolbar:
                        ' blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link ' +
                        ' | table | outdent indent| forecolor backcolor ',
                      toolbar_mode: 'wrap',
                      content_style:
                        'a { color: #096EF8; text-decoration: none }',
                      contextmenu: false, //disable the context menu since chrome doesnt support auto accessing the clipboard, otherwise the only way to access copy/paste is via keyboard shortcuts
                      removed_menuitems: 'copy paste cut pastetext', //remove these since they don't work, see above
                      images_upload_handler: onEditorImageUpload,
                      automatic_uploads: false,
                    }}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                  />
                </Grid>

                {!id && (!caseId || casePeople.length > 1) && (
                  <Grid item xs={6} className={classes.gridModalItem}>
                    {caseId ? (
                      <SelectInput
                        fullWidth
                        value={state.casePersonID}
                        onChange={handleResidentChange}
                        label={capitalize(
                          getResidentTextForCompanyType(companyType),
                        )}
                        menuItems={casePeople.filter(
                          (person) => person.isResident === true,
                        )}
                        schema={schema}
                        error={errors.casePersonID}
                        name='casePersonID'
                        disabled={state.doneOn || disableEditing}
                        required
                      />
                    ) : (
                      <AutocompleteAsync
                        key={personName}
                        disabled={state.doneOn || disableEditing}
                        fullWidth
                        style={{ flexGrow: 0 }}
                        name='casePersonID'
                        value={state.casePersonID}
                        onChange={handleResidentChange}
                        label={`Search ${getResidentTextForCompanyType(
                          companyType,
                        )}s`}
                        error={errors.casePersonID}
                        minToFetch={2}
                        url='/cases/people/basic'
                        secondaryValueField='caseID'
                        initialInputValue={personName}
                        schema={schema}
                        clearable
                        required
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={6} className={classes.gridModalItem}>
                  {!caseId && !state.casePersonID && !state.taskAssignedTo && (
                    <FormHelperText
                      sx={{ position: 'absolute', marginTop: '-24px' }}
                    >
                      Select a {getResidentTextForCompanyType(companyType)} to
                      assign task
                    </FormHelperText>
                  )}
                  <AutocompleteInput
                    fullWidth
                    textFieldProps={{
                      label: 'Assign to',
                      onChange: onChange,
                      required: true,
                      disabled:
                        state.doneOn ||
                        disableEditing ||
                        (!caseId && !state.casePersonID),
                    }}
                    autocompleteProps={{
                      options: assigneeList,
                      value:
                        state.taskAssignedTo !== ''
                          ? state.taskAssignedTo
                          : null,
                      name: 'taskAssignedTo',
                      disabled:
                        state.doneOn ||
                        disableEditing ||
                        (!caseId && !state.casePersonID),
                    }}
                    schema={schema}
                    error={errors.taskAssignedTo}
                    avatar={true}
                  />
                </Grid>
                <Grid item xs={6} className={classes.gridModalItem}>
                  <DateInput
                    fullWidth
                    value={state.dateDue}
                    name='dateDue'
                    onChange={onChange}
                    label='Date due'
                    schema={schema}
                    error={errors.dateDue}
                    disabled={state.doneOn || disableEditing}
                    isDateOnly={false}
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  title: string('Must be a string')
    .required()
    .nullable()
    .max(250, 'Max length 250 characters'),
  casePersonID: string('Must be a string').required('required').nullable(),
  taskAssignedTo: string('Must be a string').required().nullable(),
  dateDue: date().typeError('Invalid Date').required().nullable(),
  isPrivate: bool().nullable().typeError('Must be a Boolean value'),
});
