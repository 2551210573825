import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { reach } from 'yup';
import { useStyles } from './inputs.styles';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

/**
 * Material-ui Date-time picker with validation
 * @param {import("@material-ui/pickers").KeyboardDateTimePickerProps & dateTimeInputProps}
 */
export function DateTimeInput({ schema, nestedKey, ...props }) {
  const classes = useStyles();
  const {
    className,
    placeholder,
    fullWidth,
    onChange,
    name,
    value,
    error,
    addHelperTextStyle = true,
    context,
    inputVariant = 'outlined',
    minDate,
    required,
    ...otherProps
  } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setErrorMessage(error);
    if (error) {
      setIsDirty(true);
    }
  }, [error]);

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleBlur = useCallback(() => {
    // if there is already an error message let the onchange handle the validation
    if (schema && !errorMessage) {
      setIsDirty(true);
      reach(schema, nestedKey || name)
        .validate(value, { context })
        .then(() => setErrorMessage(undefined))
        .catch((err) => setErrorMessage(err.errors?.[0]));
    }
  }, [context, errorMessage, name, nestedKey, schema, value]);

  const handleChange = useCallback(
    (e) => {
      //we are checking for 'invalid date' since the new datepicker from mui v5 doesnt allow the user to clear the date using the keyboard without setting to this, and we need it to be null instead
      // eslint-disable-next-line eqeqeq
      const val = e == 'Invalid Date' ? null : e;
      const event = { target: { name: props.name, value: val } };
      typeof onChange === 'function' && onChange(event);
      const { name, value } = event.target;
      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(undefined))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [context, isDirty, nestedKey, onChange, props.name, schema],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        minDate={
          minDate
            ? typeof minDate === 'string'
              ? new Date(minDate)
              : minDate
            : null
        }
        name={name}
        value={
          value ? (typeof value === 'string' ? new Date(value) : value) : null
        }
        className={clsx({ [classes.root]: !fullWidth }, className)}
        fullWidth={fullWidth}
        // InputLabelProps={{ shrink: true }}
        variant='inline'
        inputVariant={inputVariant}
        onChange={handleChange}
        onClose={handleBlur}
        error={!!errorMessage}
        helperText={errorMessage}
        FormHelperTextProps={{
          classes: { root: clsx({ [classes.helperText]: addHelperTextStyle }) },
        }}
        autoOk
        format='MM/dd/yyyy hh:mm a'
        InputProps={{
          classes: {
            root: classes.inputRoot,
            focused: classes.focused,
            disabled: classes.disabled,
            error: classes.error,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          },
          // endAdornment: !props.required ? (
          //   <ClearIcon onClick={handleClear} style={{ cursor: 'pointer' }} />
          // ) : undefined,
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focused,
            error: classes.error,
            disabled: classes.disabled,
          },
        }}
        {...otherProps}
        slotProps={{
          textField: {
            helperText: errorMessage,
            error: !!errorMessage,
            required: required,
          },
          actionBar: {
            actions: ['clear', 'today'],
          },
        }}
        views={['year', 'month', 'day']}
      />
    </LocalizationProvider>
  );
}

// #region Typedefs
/** @typedef {object} dateTimeInputProps
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {string} error The error message when failing validation on submit
 * @property {boolean} [addHelperTextStyle] Boolean to add position absolute to helper text. Defaults to true.
 */
// #endregion
