import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { WorkIcon } from '../../../../themes';
import FilterOption from '../../ListViews/filters/FilterOption';
import { AssigneeDisplayButton } from '../AssigneeDisplayButton';
import { uiSelectors } from '../../../../state';
import { CompanyContactCard } from '../../../../components';
import { useTheme } from '@mui/material';

export function MedicaidCaseworkerDisplayInput({
  caseworker, //send the caseworker with all info or leave this out and send `caseworkerId` instead
  caseworkerId, //can send in ID instead of the actual caseworker. then the tooltip with the extra info will not render
  onChange,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const medicaidCaseworkers = useSelector(uiSelectors.medicaidCaseworkers);

  const selectedAssignee = medicaidCaseworkers.find(
    (a) => a.id === (caseworker?.id ?? caseworkerId),
  );
  const caseworkerName = selectedAssignee
    ? `${selectedAssignee.firstName} ${selectedAssignee.lastName}`
    : '';

  return (
    <FilterOption
      menuItems={[{ name: '---', id: null }, ...medicaidCaseworkers]}
      selectedMenuItems={selectedAssignee ? [selectedAssignee] : []}
      label={''}
      onChange={(values) =>
        onChange({ target: { value: values, name: 'medicaidCaseworkerID' } })
      }
      selectMenu
      closeOnChange
      searchPlaceholder={'Search...'}
      groupedOptionsLabel={'All caseworkers'}
      renderButton={({ onClick, ref }) => (
        <AssigneeDisplayButton
          displayText={caseworkerName}
          tooltipText={
            caseworker ? (
              <CompanyContactCard
                contact={caseworker}
                roleOverride='Caseworker'
                color={theme.palette.error.light}
              />
            ) : (
              ''
            )
          }
          actionText={'Add caseworker'}
          multiple={false}
          onClick={onClick}
          icon={WorkIcon}
          iconColor={'#0514B3'}
          otherProps={{ ref }}
          tooltipClasses={{ tooltip: classes.infoPaper }}
        />
      )}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  infoPaper: {
    maxWidth: 264,
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    padding: 16,
    boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
    color: theme.palette.text.primary,

    fontWeight: '400',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  cwInfoLine: {
    display: 'flex',
    alignItems: 'initial',
    marginBottom: 9,
  },
  cwInfoIcon: {
    color: theme.palette.text.secondary,
    marginRight: 11,
  },
  cwHeaderLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  cwAvatar: {
    fontSize: 16,
    height: 56,
    width: 56,
    fontWeight: 500,
    marginRight: 16,
  },
  cwTitle: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 2,
  },
  cwSecondaryText: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
