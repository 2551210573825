import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { Box, Drawer, styled } from '@mui/material';
import { AppsIcon, QuestionAnswerIcon } from '../themes';
import {
  ColoredIcon,
  FlexColumn,
  NoPrint,
  Overlay,
  QuickActionButtons,
} from '../components';
import { residentSelectors, uiActions, uiSelectors } from '../state';
import { useStyles } from './sidebarRight.styles';
import { QuickActionType, Routes } from '../lib';

export const SidebarRight = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isCaseDetailsPage = useMatch(Routes.caseDetails.path);
  const isOverlayOpen = useSelector(uiSelectors.isOverlayOpen);
  const resident = useSelector(residentSelectors.resident);

  return (
    <NoPrint>
      <Drawer
        variant='permanent'
        anchor='right'
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <div>
          {isCaseDetailsPage && (
            <AppsIcon
              className={classes.toggleOverlay}
              onClick={() => dispatch(uiActions.setOverlayOpen(!isOverlayOpen))}
              id='overlayButton'
            />
          )}
        </div>
        <FlexColumn alignItems='center'>
          {isCaseDetailsPage && (
            <QuickActionButtons
              orientation='vertical'
              size='small'
              boxStyles={{ paddingY: 1 }}
              iconStyles={(actionType) => ({
                color: ActionTypeColors[actionType],
              })}
              resident={resident}
            />
          )}

          <IntercomBox id='intercom-launcher'>
            <ColoredIcon
              Icon={QuestionAnswerIcon}
              iconColor='primary.light'
              fontSize='small'
            />
          </IntercomBox>
        </FlexColumn>
      </Drawer>
      {isOverlayOpen && <Overlay />}
    </NoPrint>
  );
});

const ActionTypeColors = {
  [QuickActionType.Application]: 'success.main',
  [QuickActionType.Notes]: 'info.main',
  [QuickActionType.Print]: 'pink.main',
  [QuickActionType.Tasks]: 'primary.main',
};

const IntercomBox = styled(Box)(() => ({
  width: '40px',
  height: '40px',
  boxShadow: '0 2px 4px 0 rgba(137,144,182,0.5)',
  borderRadius: '9px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
  transition: 'opacity 300ms linear',
  cursor: 'pointer',
  '&:hover': {
    opacity: '.5',
  },
}));
