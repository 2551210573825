import makeStyles from '@mui/styles/makeStyles';

export const inputStyles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '25ch',
  },
  inputRoot: {
    '& $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
    '&$focused:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.outlineTheme.main,
    },
  },
  smallInputRoot: {
    padding: '0 8px !important',
    height: 36,
  },
  input: {
    display: 'flex',
    padding: '16.5px 14px 14.5px 14px',
  },
  get autocompleteInputRoot() {
    return {
      ...this.inputRoot,
      padding: '0px !important',
    };
  },
  get autocompleteInput() {
    return {
      ...this.input,
      padding: '16.5px 14px 14.5px 14px !important',
    };
  },
  label: {
    color: theme.palette.textTheme.main,
    '&$focused:not($disabled):not($error)': {
      color: theme.palette.textTheme.main,
    },
  },
  notchedOutline: {},
  disabled: {},
  error: {},
  focused: {},
  helperText: {
    position: 'absolute',
    bottom: -21,
  },
  addressSearchContainer: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
  },
  addressSearchPaper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    top: 45,
    maxHeight: 350,
    overflowY: 'auto',
  },
  menuItemsHeader: {
    fontSize: 16,
    fontWeight: 500,
    margin: '8px 16px',
  },

  avatar: {
    marginRight: 12,

    fontSize: 10,
    fontWeight: 500,
    height: 24,
    width: 24,
    cursor: 'pointer',
  },
  selectOnlyLink: {
    width: '100%',
    textAlign: 'right',

    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
  clearIcon: {
    fontSize: 14,
  },
});

export const useStyles = makeStyles(inputStyles);
