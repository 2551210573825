import React, { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Button, Tabs, Tab, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { CloseIcon, RefreshIcon } from '../../themes';
import { caseSelectors, uiSelectors } from '../../state';
import { uiActions } from '../../state';
import { ActivityFeed } from './activity/ActivityFeed';
import { NotesOverlay } from './notes/NotesOverlay';
import { TabPanel } from './TabPanel';
import { useStyles } from './overlays.styles';
import { Routes } from '../../lib';
import { ToDosList } from '../../pages/main/ListViews/ToDosList';

const ContainerID = 'case-overlay';
export function Overlay() {
  // hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const pathMatch = useMatch(Routes.caseDetails.path);
  // selectors
  const isOverlayOpen = useSelector(uiSelectors.isOverlayOpen);
  const people = useSelector(caseSelectors.casePeople);
  // state
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const tabs = useMemo(() => {
    return [
      { label: 'Notes', index: 0, component: NotesOverlay },
      {
        label: "To Do's",
        index: 1,
        component: ToDosList,
        refreshAction: uiActions.setRefreshTodoList,
      },
      {
        label: 'Activity',
        index: 2,
        component: ActivityFeed,
        refreshAction: uiActions.setRefreshActivityFeed,
      },
    ];
  }, []);

  useEffect(() => {
    if (!pathMatch && isOverlayOpen) {
      dispatch(uiActions.setOverlayOpen(false));
    }
  }, [isOverlayOpen, pathMatch, dispatch]);

  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  const refresh = useCallback(() => {
    var currentTab = tabs.find((t) => t.index === selectedTabIndex);
    dispatch(currentTab.refreshAction(true));
  }, [dispatch, selectedTabIndex, tabs]);

  return (
    <div
      className={clsx(classes.overlay, isOverlayOpen ? 'open' : 'closed')}
      id={ContainerID}
    >
      <div className={classes.overlayHeader}>
        <div className={classes.overlayHeaderFlexRow}>
          <div>
            <p className={classes.title}>{people[0]?.name}</p>
          </div>
          <div className={classes.overlayClose}>
            <Button
              onClick={() => dispatch(uiActions.setOverlayOpen(false))}
              variant='text'
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChange}
          className={classes.overlayTabs}
          indicatorColor='primary'
          textColor='primary'
        >
          {tabs.map((t) => (
            <Tab label={t.label} key={t.index} />
          ))}
        </Tabs>
        {tabs.find((t) => t.index === selectedTabIndex).refreshAction && (
          <IconButton onClick={refresh} sx={{ float: 'right', mt: -5 }}>
            <RefreshIcon />
          </IconButton>
        )}
      </div>
      {tabs.map((t) => {
        const Component = t.component;
        return (
          <TabPanel key={t.index} value={selectedTabIndex} index={t.index}>
            <Component containerId={ContainerID} />
          </TabPanel>
        );
      })}
    </div>
  );
}
